import React, { useEffect, useRef } from "react";

const loadGoogleMapsApi = (callback) => {
    const existingScript = document.getElementById("google-maps-api");

    if (!existingScript) {
        console.log("Adding Google Maps API script...");
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&libraries=places&v=beta`;
        script.id = "google-maps-api";
        script.async = true;
        script.defer = true;
        script.onload = () => {
            console.log("Google Maps API script loaded");
            if (callback) callback();
        };
        script.onerror = () => console.error("Failed to load the Google Maps API script.");
        document.body.appendChild(script);
    } else {
        console.log("Google Maps API script already exists");
        if (callback) callback();
    }
};

const MapComponent = () => {
    const mapRef = useRef(null);
    const location = { lat: 16.502835805055888, lng: 80.63941747671441 }; // Example coordinates

    useEffect(() => {
        loadGoogleMapsApi(() => {
            if (window && window.google && window.google.maps) {
                const map = new window.google.maps.Map(mapRef.current, {
                    center: location,
                    zoom: 17,
                });

                // Check if AdvancedMarkerElement is defined
                if (window.google.maps.marker && window.google.maps.marker.AdvancedMarkerElement) {
                    const marker = new window.google.maps.marker.AdvancedMarkerElement({
                        position: location,
                        map: map,
                        content: '<div style="color:red;"><strong>Location</strong></div>',
                    });

                    // Clean up on component unmount
                    return () => {
                        marker.setMap(null);
                    };
                } else {
                    console.error("AdvancedMarkerElement is not available. Please ensure you are using a supported version of the Google Maps API.");
                }
            } else {
                console.error("Google Maps API not loaded");
            }
        });
    }, []);

    return (
        <div
            ref={mapRef}
            style={{
                height: "400px",
                width: "100%",
                borderRadius: "8px",
                overflow: "hidden",
            }}
        />
    );
};

export default MapComponent;
