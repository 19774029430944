import Cookies from "js-cookie";
import axiosInstance from "../config/axiosConfig";

export const getTrainings = async () => {
    try {
        return await axiosInstance.get("/api/training");
    } catch (err) {
        throw err;
    }
};

export const getTraining = async (id) => {
    try {
        return await axiosInstance.get(`/api/training/${id}`);
    } catch (err) {
        throw err;
    }
};

export const addTraining = async (data) => {
    try {
        return await axiosInstance.post("/api/training", data, {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
            },
        });
    } catch (err) {
        throw err;
    }
};

export const updateTraining = async (id, data) => {
    try {
        return await axiosInstance.put(`/api/training/${id}`, data, {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
            },
        });
    } catch (err) {
        throw err;
    }
};

export const deleteTraining = async (id) => {
    try {
        return await axiosInstance.delete(`/api/training/${id}`, {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
            },
        });
    } catch (err) {
        throw err;
    }
};
