import React, { useEffect } from "react";
import { isLogin } from "../../services/authService";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";

export default function AdminDashboard() {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Techlogics | Admin Dashboard";
        if (!isLogin()) navigate("/admin-login");
    }, []);
    return <Box p={2}>
        
    </Box>;
}
