import axiosInstance from "../config/axiosConfig";
import Cookies from "js-cookie";

export const getProjectById = async (id) => {
    try {
        return await axiosInstance.get(`/api/project/${id}`);
    } catch (error) {
        throw error;
    }
};
export const getProjects = async () => {
    try {
        return await axiosInstance.get("/api/project");
    } catch (error) {
        throw error;
    }
};

export const addProject = async (project) => {
    const token = Cookies.get("token");
    try {
        return await axiosInstance.post("/api/project", project, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        throw error;
    }
};
export const updateProject = async (id, project) => {
    const token = Cookies.get("token");
    try {
        return await axiosInstance.put(`/api/project/${id}`, project, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        throw error;
    }
};
export const deleteProject = async (id) => {
    const token = Cookies.get("token");
    try {
        return await axiosInstance.delete(`/api/project/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        throw error;
    }
};
