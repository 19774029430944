import React from "react";
import Slider from "react-slick";
import { Card, CardContent, CardMedia, Typography, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import academicImage from "../../assets/images/academic.jpg";
import abroadImage from "../../assets/images/abroad.jpg";
import dissertationImage from "../../assets/images/dissertation.jpg";
import publicationImage from "../../assets/images/publication.jpg";
import assignmentImage from "../../assets/images/assignment.jpg";
import internshipImage from "../../assets/images/internship.jpg";
import trainingImage from "../../assets/images/training.jpg";
import ProjectCard from "./projectCard";

const ProjectCardCarousal = (props) => {
    const { projects } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile view
    const settings = {
        dots: true, // Display dots navigation below the carousel
        infinite: true, // Infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 4, // Number of slides to show at a time
        slidesToScroll: 2, // Number of slides to scroll at a time
        autoplay: true, // Enable automatic sliding
        autoplaySpeed: 2000, // Slide every 3 seconds
        responsive: [
            {
                breakpoint: 1024, // Below this breakpoint (e.g., tablets), show 2 cards
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 600, // Below this breakpoint (e.g., mobile), show 1 card
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // centerMode: true, // Enable centering the card on mobile
                    // centerPadding: "16px", 
                },
            },
        ],
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant={isMobile ? "h5" : "h4"} align='center' color='#242f62' gutterBottom>
                Trending Projects
            </Typography>

            <Slider  {...settings}>
                {projects.slice(0, 6).map((project, index) => (
                    <ProjectCard key={index} project={project} />
                ))}
            </Slider>
        </Box>
    );
};

export default ProjectCardCarousal;
