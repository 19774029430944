import React, { useState } from "react";
import { Button, Tooltip, CircularProgress, IconButton, Typography, Stack } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const InteractiveButton = ({ filteredProjects, selectedDepartment, deptLink }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        setIsLoading(true);
        // Open link in a new tab
        window.open(deptLink, "_blank", "noopener,noreferrer");
        // Reset loading state after a brief delay for better UX
        setTimeout(() => setIsLoading(false), 1000);
    };

    return (
        <Stack direction='row' alignItems='center' spacing={1} sx={{ mb: 2 }}>
            {filteredProjects.length > 0 && selectedDepartment !== "All" && (
                <>
                    <Typography>Download project list for {selectedDepartment} department</Typography>
                    <Button
                        onClick={handleClick}
                        disabled={isLoading}
                        variant='outlined'
                        startIcon={isLoading ? <CircularProgress size={20} color='inherit' /> : <FileDownloadOutlinedIcon />}
                        sx={{
                            textTransform: "none",
                            // fontWeight: "bold",
                            // fontSize: "1rem",
                            // borderRadius: "8px",
                            transition: "transform 0.2s",
                            "&:hover": {
                                // backgroundColor: "#1976d2",
                                transform: "scale(1.01)",
                            },
                        }}
                    >
                        {isLoading ? "Loading..." : `Download`}
                    </Button>
                </>
            )}
        </Stack>
    );
};

export default InteractiveButton;
