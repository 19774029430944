import axiosInstance from "../config/axiosConfig";
import Cookies from "js-cookie";

export const getDepartments = async () => {
    try {
        return await axiosInstance.get("/api/department");
    } catch (error) {
        throw error;
    }
};

export const getDepartment = async (id) => {
    try {
        return await axiosInstance.get(`/api/department/${id}`);
    } catch (error) {
        throw error;
    }
};

export const getDepartmentByCode = async (id) => {
    try {
        return await axiosInstance.get(`/api/department/code/${id}`);
    } catch (error) {
        throw error;
    }
};

export const addDepartment = async (data) => {
    const token = Cookies.get("token");

    try {
        return await axiosInstance.post("/api/department", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        throw error;
    }
};

export const updateDepartment = async (id, data) => {
    const token = Cookies.get("token");
    try {
        return await axiosInstance.put(`/api/department/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        throw error;
    }
};

export const deleteDepartment = async (id) => {
    const token = Cookies.get("token");
    try {
        return await axiosInstance.delete(`/api/department/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        throw error;
    }
};
