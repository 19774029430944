import React, { useState } from "react";
import adminSchema from "./adminSchema";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PasswordIcon from "@mui/icons-material/Password";
import { login, verify } from "../../services/authService";
import Snackbar from "@mui/material/Snackbar";

import {
    FormControl,
    InputLabel,
    Button,
    Select,
    MenuItem,
    Typography,
    Paper,
    Box,
    TextField,
    FormHelperText,
    IconButton,
    InputAdornment,
    LinearProgress,
    OutlinedInput,
    Stack,
} from "@mui/material";
import Cookies from "js-cookie";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../common/loader";

const Adminlogin = () => {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");

    const [errors, setErrors] = useState({});
    const [status, setStatus] = useState("");
    const [password, setPassword] = useState("");
    const [showOtp, setShowOtp] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [progressMessage, setProgressMessage] = useState("");

    const navigate = useNavigate();

    const openAlert = (status, message) => {
        setStatus(status);
        setErrorMessage(message);
        setIsAlertOpen(true);
    };

    const closeAlert = () => {
        setIsAlertOpen(false);
    };
    const handleOtpChange = (event) => {
        setOtp(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLogin = () => {
        adminSchema
            .validate({ email, password }, { abortEarly: false })
            .then(() => {
                setIsLoading(true);
                setProgressMessage("Validating credentials");
                login({
                    username: email,
                    password,
                    role: "admin",
                })
                    .then((response) => {
                        setIsLoading(false);
                        setShowOtp(true);
                        setOtp(response.data.data);
                        toast.success("OTP sent to your email");
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        openAlert("error", error.response.data.message);
                        toast.error(error.response.data.message || "Failed to login");
                    });
            })
            .catch((validationErrors) => {
                const validationErrorMap = {};
                validationErrors.inner.forEach((error) => {
                    validationErrorMap[error.path] = error.message;
                });
                setErrors(validationErrorMap);
            });
    };

    const handleVerifyLogin = () => {
        setIsLoading(true);
        setProgressMessage("Verifying OTP");
        verify({
            email,
            role: "admin",
            otp,
        })
            .then((response) => {
                toast.success("Login success");
                Cookies.remove("token"); 
                Cookies.set("token", response.data.data.token, { expires: 1 / 24, secure: true, sameSite: "Strict" });
                setTimeout(() => {
                    setIsLoading(false);
                    navigate("/admin/dashboard/tab=0");
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                toast.error(err.response.data.message || "Failed to login");
            });
    };

    return (
        <Box width={400} margin={"100px auto"}>
            <Paper elevation={5}>
                {isLoading && <Loader message={progressMessage} />}
                {!isLoading && (
                    <>
                        {!showOtp && (
                            <Box p={3}>
                                <Stack direction='column' gap={2}>
                                    <Typography textAlign={"center"} variant='h5'>
                                        Sign in | Administrator
                                    </Typography>
                                    <Divider />
                                    <OutlinedInput
                                    size="small"
                                        required
                                        fullWidth
                                        type='email'
                                        value={email}
                                        id='outlined-email'
                                        error={!!errors.email}
                                        onChange={handleEmailChange}
                                        placeholder='Email'
                                        startAdornment={
                                            <InputAdornment position='start'>
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            errors?.email && (
                                                <InputAdornment position='end'>
                                                    <Typography variant='caption' color='error'>
                                                        {errors?.email}
                                                    </Typography>
                                                </InputAdornment>
                                            )
                                        }
                                    />
                                    <OutlinedInput
                                    size="small"
                                        required
                                        fullWidth
                                        value={password}
                                        id='outlined-password'
                                        placeholder='Password'
                                        error={!!errors.password}
                                        onChange={handlePasswordChange}
                                        type={showPassword ? "text" : "password"}
                                        startAdornment={
                                            <InputAdornment position='start'>
                                                <PasswordIcon />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <>
                                                <InputAdornment position='end'>
                                                    {errors?.password && (
                                                        <InputAdornment position='end'>
                                                            <Typography variant='caption' color='error'>
                                                                {errors?.password}
                                                            </Typography>
                                                        </InputAdornment>
                                                    )}
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge='end'
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            </>
                                        }
                                    />
                                    <Button fullWidth sx={{ backgroundColor: "#111", color: "whitesmoke" }} variant='outlined' onClick={handleLogin}>
                                        Login as admin
                                    </Button>
                                </Stack>
                            </Box>
                        )}
                        {showOtp && (
                            <div id='otp'>
                                <Box p={3}>
                                    <Typography variant='h5'>Authentication required</Typography>
                                    <br />
                                    {email && (
                                        <>
                                            <Typography variant='body2'>
                                                {email}{" "}
                                                <Link style={{ textDecoration: "none" }} onClick={() => setShowOtp(false)}>
                                                    Change
                                                </Link>
                                            </Typography>
                                            <br />
                                        </>
                                    )}
                                    <Typography variant='body2'>
                                        We’ve sent a One Time Password (OTP) to the above email. Please enter it to complete verification{" "}
                                    </Typography>
                                    <br />
                                    <TextField
                                    size="small"
                                        required
                                        fullWidth
                                        type='otp'
                                        value={otp}
                                        id='outlined-otp'
                                        error={!!errors.otp}
                                        helperText={errors.otp}
                                        onChange={handleOtpChange}
                                        placeholder='Enter OTP'
                                    />
                                    <br />
                                    <br />

                                    <Button size="small" disabled={!otp} fullWidth sx={{ backgroundColor: "#111", color: "whitesmoke" }} onClick={handleVerifyLogin}>
                                        continue
                                    </Button>
                                </Box>
                            </div>
                        )}
                    </>
                )}
            </Paper>
        </Box>
    );
};

export default Adminlogin;
