import React from "react";
import { Box, Typography, List, ListItem, ListItemText, Button, ListItemIcon, useMediaQuery, Grid } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description"; // Icon for Abstract
import SlideshowIcon from "@mui/icons-material/Slideshow"; // Icon for PPT
import ArticleIcon from "@mui/icons-material/Article"; // Icon for Documentation
import BuildIcon from "@mui/icons-material/Build"; // Icon for Execution
import HelpIcon from "@mui/icons-material/Help"; // Icon for Explanation
import CodeIcon from "@mui/icons-material/Code"; // Icon for Source Code
import ImageIcon from "@mui/icons-material/Image"; // Icon for Screenshots
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary"; // Icon for Videos
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications"; // Icon for Softwares
import { useTheme } from "@emotion/react";

const Deliverables = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box className='deliverables-banner'>
            <Box className='deliverables-banner-text' p={isMobile ? 2 : 3}>
                <Typography
                    variant={isMobile ? "h5" : "h4"}
                    gutterBottom
                    sx={{
                        fontWeight: "bold",
                        color: "#242f62",
                        marginBottom: 2,
                    }}
                >
                    Project Deliverables
                </Typography>

                <List>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={12}>
                            <ListItem>
                                <ListItemIcon>
                                    <DescriptionIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText primary='Abstract' />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <SlideshowIcon color='secondary' />
                                </ListItemIcon>
                                <ListItemText primary='Presentation' />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <ArticleIcon color='success' />
                                </ListItemIcon>
                                <ListItemText primary='Documentation' />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <BuildIcon color='error' />
                                </ListItemIcon>
                                <ListItemText primary='Execution' />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <HelpIcon color='warning' />
                                </ListItemIcon>
                                <ListItemText primary='Explanation' />
                            </ListItem>
                        </Grid>
                        <Grid item xs={6} sm={12}>
                            <ListItem>
                                <ListItemIcon>
                                    <CodeIcon color='info' />
                                </ListItemIcon>
                                <ListItemText primary='Code Base' />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <ImageIcon color='secondary' />
                                </ListItemIcon>
                                <ListItemText primary='Screenshots' />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <VideoLibraryIcon color='error' />
                                </ListItemIcon>
                                <ListItemText primary='Videos' />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <SettingsApplicationsIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText primary='Softwares' />
                            </ListItem>
                        </Grid>
                    </Grid>
                </List>
            </Box>
        </Box>
    );
};

export default Deliverables;
