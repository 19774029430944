import { Box, CircularProgress, LinearProgress } from "@mui/material";
import React from "react";

function Loader({ message }) {
    return (
        <Box
            p={2}
            // width={"100%"}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress sx={{ margin: "0 auto" }} color='black' />
            {message}
        </Box>
    );
}

export default Loader;
