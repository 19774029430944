import Cookies from "js-cookie";
import axiosInstance from "../config/axiosConfig";

export const addDiscount = async (body) => {
    try {
        const token = Cookies.get("token");
        return await axiosInstance.post("/api/discount", body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (err) {
        throw err;
    }
};

export const getDiscount = async () => {
    try {
        const token = Cookies.get("token");
        return await axiosInstance.get("/api/discount", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (err) {
        throw err;
    }
};
