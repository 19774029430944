import axiosInstance from "../config/axiosConfig";
import Cookies from "js-cookie";

export const login = async (body) => {
    try {
        return await axiosInstance.post("/api/auth/login", body);
    } catch (error) {
        throw error;
    }
};

export const verify = async (body) => {
    try {
        return await axiosInstance.post("/api/auth/verifyLogin", body);
    } catch (error) {
        throw error;
    }
};

export const isLogin = () => {
    let token = Cookies.get("token");
    return token != undefined;
};
