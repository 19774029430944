import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Divider } from "@mui/material";

const ActionDialog = ({ mode, open, onClose, title, content, onConfirm, confirmLabel = "Confirm", cancelLabel = "Cancel" }) => {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby='action-dialog-title'>
            <DialogTitle id='action-dialog-title'>{title}</DialogTitle>
            {/* <Divider></Divider> */}
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='' variant="outlined">
                    {cancelLabel}
                </Button>
                <Button onClick={onConfirm} color={mode === "update" ? "primary" : "error"} variant='contained'>
                    {mode === "update" ? "Update" : "Delete"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ActionDialog;
