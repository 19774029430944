import { useTheme } from "@emotion/react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function Project404() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Box className='project-detail-banner-404'>
            <Box sx={{ width: "40%" }} className='project-detail-banner-text1' p={isMobile ? 3 : 6} pl={isMobile ? 3 : 12}>
                <Grid p={isMobile ? 3 : 2} container spacing={2}>
                    <Grid item xs={12} md={12} textAlign='start'>
                        <Typography color='#e5057f' variant={isMobile ? "h3" : "h1"} gutterBottom>
                            404
                        </Typography>
                        <Typography variant={isMobile ? "body1" : "h4"} gutterBottom>
                            Project Not Found
                        </Typography>
                        <Typography variant={isMobile ? "body2" : "h5"} gutterBottom>
                            Head to{" "}
                            <Link style={{ color: "#e5057f", textDecoration: "none" }} to='/projects'>
                                Projects
                            </Link>{" "}
                            page and navigate from there
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default Project404;
