import * as Yup from "yup";

// Define Yup validation schema
const testimonialSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    rating: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
});

export default testimonialSchema;
