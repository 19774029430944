import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import { getProjectById } from "../../services/projectService";
import { Box, Card, CardMedia, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

import Project404 from "./project404";
import ProjectDetailBanner from "./projectDetailBanner";
import Loader from "../common/loader";
import Deliverables from "./deliverables";
import Header from "../header/header";
import Footer from "../footer/footer";

function ProjectDetails() {
    const { id } = useParams();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [image, setImage] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        getProjectById(id)
            .then((project) => {
                setProject(project.data.data);
                let img = project.data.data.youtubeLink.split("v=")[1];
                setImage(img);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [id]);

    return (
        <div>
            <Header />
            {loading && <Loader message={"Getting project details..."} />}
            {!loading && project && (
                <>
                    {image && isMobile && (
                        <Card
                            sx={{
                                position: "fixed",
                                top: 55,
                                left: 0,
                                width: "100%",
                                zIndex: 1000, // Ensures it stays on top
                                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                            }}
                        >
                            <CardMedia component='iframe' height='300' image={`https://www.youtube.com/embed/${image}`} title={project?.title} />
                        </Card>
                    )}

                    <Box sx={{ mt: isMobile && image ? 38 : 0 }}>
                        <ProjectDetailBanner image={image} project={project} />
                        <Box p={isMobile ? 2 : 3} m={isMobile ? 2 : 3} className='deliverables-banner-text'>
                            <Typography
                                variant={isMobile ? "h5" : "h4"}
                                gutterBottom
                                sx={{
                                    fontWeight: "bold",
                                    color: "#242f62",
                                }}
                            >
                                Project Description
                            </Typography>
                            <Typography variant='body1' textAlign={"justify"}>
                                {project?.description}
                            </Typography>
                        </Box>
                        <Box p={isMobile ? 2 : 3} pt={0}>
                            <Deliverables />
                        </Box>
                    </Box>
                </>
            )}
            {!loading && !project && <Project404 />}
            <Footer />
        </div>
    );
}

export default ProjectDetails;
