import axiosInstance from "../config/axiosConfig";
import Cookies from "js-cookie";

export const addMessage = async (body) => {
    try {
        return await axiosInstance.post("/api/contact", body);
    } catch (error) {
        throw error;
    }
};

export const getMessages = async () => {
    let token = Cookies.get("token");
    try {
        return await axiosInstance.get("/api/contact", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        throw error;
    }
};
