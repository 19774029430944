import React, { useRef, useState } from "react";
import axios from "axios";
import { Box, Button, InputAdornment, OutlinedInput, Stack, TextField } from "@mui/material";
import { uploadImage } from "../../services/commonService";

const ImageUpload = ({ onImageUpload }) => {
    const [file, setFile] = useState();
    const [error, setError] = useState("");
    const [uploading, setUploading] = useState(false);
    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        if (!file) return setError("Please select a file.");

        setUploading(true);
        const formData = new FormData();
        formData.append("image", file);

        try {
            uploadImage(formData).then((res) => {
                console.log(res);
                setFile();
                setUploading(false);
                setError("");
                fileInputRef.current.value = "";
                onImageUpload(res.data.filePath);
            });
        } catch (err) {
            setUploading(false);
            setError("Failed to upload image.");
        }
    };

    return (
        <form onSubmit={handleUpload}>
            <Stack direction={"row"} gap={2} pb={2}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <input type='file' ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
                    <OutlinedInput
                        sx={{ pr: 0 }}
                        size='small'
                        value={file && file.name}
                        // onClick={handleButtonClick}
                        readOnly
                        placeholder='No file chosen'
                        fullWidth
                        variant='outlined'
                        endAdornment={
                            <InputAdornment position='end'>
                                <Button variant='contained' color="" component='span' onClick={handleButtonClick}>
                                    Choose File
                                </Button>
                            </InputAdornment>
                        }
                    />
                </Box>
                <Button type='submit' variant='contained' color="" disabled={uploading}>
                    Upload Image
                </Button>
            </Stack>
            {error && <p>{error}</p>}
        </form>
    );
};

export default ImageUpload;
