// frontend/src/VisitorTracker.js
import React, { useEffect, useState } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

import { addVisitor } from "../../services/visitorService";
import AnimatedCounter from "./animatedCounter";
import { Box, Stack, Typography } from "@mui/material";

const VisitorTracker = () => {
    const [visitorCount, setVisitorCount] = useState(0);

    useEffect(() => {
        const trackVisitor = async () => {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            const visitorId = result.visitorId;

            addVisitor({ visitorId })
                .then((res) => {
                    setVisitorCount(res.data.data.visitorCount);
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        trackVisitor();
    }, []);

    return (
        <Box pb={4}>
            <Stack direction={"row"} alignItems={"center"} gap={2} justifyContent={'center'}>
                <Typography variant='h6'>Visitor Count</Typography>
                <AnimatedCounter value={visitorCount} />
            </Stack>
        </Box>
    );
};

export default VisitorTracker;
