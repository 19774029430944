import Cookies from "js-cookie";
import axiosInstance from "../config/axiosConfig";

export const addTestimonial = async (body) => {
    try {
        return await axiosInstance.post("/api/testimonial", body, {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
            },
        });
    } catch (error) {
        throw error;
    }
};

export const getTestimonials = async () => {
    try {
        return await axiosInstance.get("/api/testimonial");
    } catch (error) {
        throw error;
    }
};

export const deleteTestimonial = async (id) => {
    try {
        return await axiosInstance.delete(`/api/testimonial/${id}`, {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
            },
        });
    } catch (error) {
        throw error;
    }
};

export const updateTestimonial = async (id, body) => {
    try {
        return await axiosInstance.put(`/api/testimonial/${id}`, body, {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
            },
        });
    } catch (error) {
        throw error;
    }
};

export const getTestimonialById = async (id) => {
    try {
        return await axiosInstance.get(`/api/testimonial/${id}`);
    } catch (error) {
        throw error;
    }
};
