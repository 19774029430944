import { Box, Button, Divider, IconButton, InputAdornment, Typography, OutlinedInput, Stack, Switch, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../common/loader";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { addBanner, deleteBanner, getBannerById, updateBanner } from "../../../services/bannerService";
import * as Yup from "yup";
import ImageUpload from "../../common/ImageUpload";
import ActionDialog from "../../common/actionDialog";

const bannerSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    image: Yup.string().required("Required"),
});

export default function AddBanner({ mode }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [checked, setChecked] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [errors, setErrors] = React.useState({});
    const [imageUrls, setImageUrls] = useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [banner, setBanner] = React.useState({
        title: "",
        description: "",
        image: "",
        isActive: false,
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState({
        title: "",
        content: "",
        onConfirm: null,
        onCancel: null,
        mode: "",
    });

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const handleActiveChange = (event) => {
        setBanner({ ...banner, isActive: event.target.checked });
    };

    const handleImageUpload = (url) => {
        setBanner({ ...banner, image: url });
        setImageUrls([...imageUrls, url]);
    };

    useEffect(() => {
        setErrors({});
        setChecked(false);
        if (mode === "view") {
            getBannerById(id)
                .then((res) => {
                    setBanner(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (mode === "add") {
            setBanner({
                title: "",
                description: "",
                image: "",
                isActive: "",
            });
        }
    }, [id, isLoading]);

    // Save Department function with Yup validation
    const saveBanner = () => {
        setErrors({}); // Clear previous errors
        bannerSchema
            .validate(banner, { abortEarly: false })
            .then((validatedData) => {
                if (mode === "add") {
                    setIsLoading(true);
                    setMessage("Saving banner");
                    addBanner(validatedData)
                        .then((res) => {
                            toast.success("Banner added");
                            setTimeout(() => {
                                setIsLoading(false);
                                window.location.href = "/admin/banners/tab=4";
                            }, 3000);
                        })
                        .catch((err) => {
                            toast.error(err.response.data.message || "Error adding banner");
                            console.log(err);
                            setIsLoading(false);
                        });
                } else if (mode === "view") {
                    setDialogData({
                        mode: "update",
                        title: "Confirm Update",
                        content: `Do you want to update?`,
                        onConfirm: () => {
                            setIsLoading(true);
                            setMessage("Updating banner");
                            updateBanner(banner.id, validatedData)
                                .then((res) => {
                                    setDialogOpen(false);
                                    toast.success("Banner updated");
                                    setTimeout(() => {
                                        setIsLoading(false);
                                        window.location.href = "/admin/banners/tab=4";
                                    }, 3000);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    toast.error(err.response.data.message || "Error updating banner");
                                    setIsLoading(false);
                                });
                        },
                        onClose: () => {
                            setDialogOpen(false);
                            setIsLoading(false);
                        },
                    });
                    setDialogOpen(true);
                }
            })
            .catch((validationErrors) => {
                // Map Yup validation errors to state
                const formattedErrors = {};
                validationErrors.inner.forEach((error) => {
                    formattedErrors[error.path] = error.message;
                });

                setErrors(formattedErrors);
            });
    };

    const handleDeleteBanner = () => {
        setDialogData({
            mode: "delete",
            title: "Confirm Deletion",
            content: `Are you sure you want to delete? This action cannot be undone`,
            onConfirm: () => {
                setIsLoading(true);
                setMessage("Deleting banner");
                deleteBanner(banner.id)
                    .then((res) => {
                        setDialogOpen(false);
                        toast.success("Banner deleted");
                        setTimeout(() => {
                            setIsLoading(false);
                            window.location.href = "/admin/banners/tab=4";
                        }, 3000);
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error(err.response.data.message || "Error deleting banner");
                        setIsLoading(false);
                    });
            },
            onClose: () => {
                setDialogOpen(false);
                setIsLoading(false);
            },
        });
        setDialogOpen(true);
    };

    return (
        <Box>
            <ActionDialog
                mode={dialogData.mode}
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    setIsLoading(false);
                }}
                title={dialogData.title}
                content={dialogData.content}
                onConfirm={dialogData.onConfirm}
            />
            {isLoading && <Loader message={message} />}
            {!isLoading && (
                <>
                    {mode === "add" && (
                        <Typography className='purple' variant='h6'>
                            Add Banner
                        </Typography>
                    )}
                    {mode === "view" && (
                        <>
                            <Typography variant='h6'>Banner details</Typography>
                            <FormControlLabel
                                value='end'
                                control={<Switch checked={checked} onChange={handleChange} color='primary' />}
                                label='Edit mode'
                                labelPlacement='end'
                            />
                        </>
                    )}
                    <Box py={1}>
                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position='start'>
                                    <Typography width={90} variant='body1'>
                                        Title
                                    </Typography>
                                    <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                </InputAdornment>
                            }
                            sx={{ mb: 2 }}
                            fullWidth
                            readOnly={mode === "view" && !checked}
                            value={banner.title}
                            onChange={(e) =>
                                setBanner({
                                    ...banner,
                                    title: e.target.value,
                                })
                            }
                            size='small'
                            variant='outlined'
                            error={!!errors?.title} // Display error if exists
                            endAdornment={
                                errors?.title && (
                                    <InputAdornment position='end'>
                                        <Typography variant='caption' color='error'>
                                            {errors?.title}
                                        </Typography>
                                    </InputAdornment>
                                )
                            }
                        />

                        {/* Name Field */}
                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position='start'>
                                    <Typography width={90} variant='body1'>
                                        Description
                                    </Typography>
                                    <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                </InputAdornment>
                            }
                            fullWidth
                            readOnly={mode === "view" && !checked}
                            sx={{ mb: 2 }}
                            value={banner.description}
                            onChange={(e) =>
                                setBanner({
                                    ...banner,
                                    description: e.target.value,
                                })
                            }
                            size='small'
                            variant='outlined'
                            error={!!errors?.description} // Display error if exists
                            endAdornment={
                                errors?.description && (
                                    <InputAdornment position='end'>
                                        <Typography variant='caption' color='error'>
                                            {errors?.description}
                                        </Typography>
                                    </InputAdornment>
                                )
                            }
                        />

                        {mode === "add" && <ImageUpload onImageUpload={handleImageUpload} />}
                        {mode === "view" && checked && <ImageUpload onImageUpload={handleImageUpload} />}

                        {banner.image && (
                            <OutlinedInput
                                // readOnly
                                startAdornment={
                                    <InputAdornment position='start'>
                                        <Typography width={90} variant='body1'>
                                            Image Link
                                        </Typography>
                                        <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                    </InputAdornment>
                                }
                                fullWidth
                                readOnly
                                sx={{ mb: 2 }}
                                value={banner.image}
                                onChange={(e) =>
                                    setBanner({
                                        ...banner,
                                        image: e.target.value,
                                    })
                                }
                                size='small'
                                variant='outlined'
                                error={!!errors?.image} // Display error if exists
                                endAdornment={
                                    errors?.image && (
                                        <InputAdornment position='end'>
                                            <Typography variant='caption' color='error'>
                                                {errors?.image}
                                            </Typography>
                                        </InputAdornment>
                                    )
                                }
                            />
                        )}
                        {mode === "add" ? (
                            <FormControlLabel
                                value='end'
                                control={<Switch checked={banner.isActive} onChange={handleActiveChange} color='success' />}
                                label='Active'
                                labelPlacement='end'
                            />
                        ) : checked ? (
                            <FormControlLabel
                                value='end'
                                control={<Switch checked={banner.isActive} onChange={handleActiveChange} color='success' />}
                                label='Active'
                                labelPlacement='end'
                            />
                        ) : (
                            ""
                        )}

                        <Stack direction={"row"} gap={1} py={1}>
                            {mode === "add" && (
                                <Button sx={{ backgroundColor: "#30369e" }} variant='contained' onClick={saveBanner}>
                                    Save Banner
                                </Button>
                            )}
                            {checked && (
                                <Button sx={{ color: "#30369e" }} variant='outlined' onClick={saveBanner}>
                                    update banner
                                </Button>
                            )}
                            {mode === "view" && (
                                <Button variant='contained' color='error' onClick={handleDeleteBanner}>
                                    delete
                                </Button>
                            )}
                        </Stack>
                    </Box>
                </>
            )}
        </Box>
    );
}
