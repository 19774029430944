import React, { useEffect, useState } from "react";
import { getBanners } from "../../../services/bannerService";
import { isLogin } from "../../../services/authService";
import { toast } from "react-toastify";
import { Box, Button, Divider, IconButton, InputAdornment, OutlinedInput, Stack, Tooltip, Typography } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CachedIcon from "@mui/icons-material/Cached";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "../../common/loader";
import { Outlet, useNavigate } from "react-router";
import { isMobile } from "react-device-detect";
import BannerTable from "./table";

export default function Banners() {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [reload, setReload] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [banners, setBanners] = React.useState([]);
    const [filteredBanners, setFilteredBanners] = React.useState([]);

    useEffect(() => {
        document.title = "Techlogics | Banners";
        if (!isLogin()) navigate("/admin-login");

        getBanners()
            .then((res) => {
                setBanners(res.data.data);
                setFilteredBanners(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, [reload]);

    const handleSearch = (e) => {
        setSearch(e.target.value);
        if (e.target.value === "") {
            setFilteredBanners(banners);
        } else {
            const filtered = banners.filter((banner) => {
                return banner.title.toLowerCase().includes(e.target.value.toLowerCase());
            });
            setFilteredBanners(filtered);
        }
    };

    const handleClearSearch = () => {
        setSearch("");
        setFilteredBanners(banners);
    };

    const handleRefresh = () => {
        setSearch("");
        setIsLoading(true);
        setReload(!reload);
        toast.success("Banners Refreshed");
    };

    return (
        <Box p={2} gap={2} sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
            <Box width={{ xs: "100%", sm: "90%" }}>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <Typography className='purple' variant='h6'>
                        Banner List
                    </Typography>
                    <Tooltip title='Refresh' placement='right'>
                        <IconButton size='small' onClick={handleRefresh}>
                            <CachedIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
                <Box py={1} sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: 1, alignItems: "center" }}>
                    <OutlinedInput
                        fullWidth
                        type='text'
                        placeholder='Search Department'
                        sx={{
                            flex: 1,
                        }}
                        size='small'
                        value={search}
                        onChange={handleSearch}
                        endAdornment={
                            <Tooltip title='Clear'>
                                <IconButton onClick={handleClearSearch}>
                                    <FilterAltOffIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        startAdornment={
                            <InputAdornment position='start'>
                                <SearchIcon color='primary' sx={{ marginRight: 1 }} />
                            </InputAdornment>
                        }
                    />
                    <Button
                        // fullWidth={{ xs: true, md: false }}
                        size={isMobile ? "small" : "medium"}
                        variant='contained'
                        sx={{ color: "#30369e" }}
                        color=''
                        onClick={() => navigate("/admin/banners/tab=4/add")}
                    >
                        Add banner
                    </Button>
                </Box>
                {isLoading && <Loader message={"Fetching banner details"} />}
                {!isLoading && <BannerTable filteredBanners={filteredBanners} />}
            </Box>
            <Divider orientation={isMobile ? "horizontal" : "vertical"} flexItem />
            <Box width={"100%"} sx={{ xs: 0, sm: 1 }}>
                <Outlet />
            </Box>
        </Box>
    );
}
