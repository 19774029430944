import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import Cookies from "js-cookie";
import { Tooltip } from "@mui/material";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BusinessIcon from "@mui/icons-material/Business";
import AppsIcon from "@mui/icons-material/Apps";
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";

import dashboardIcon from "../../assets/icons/dashboard.png";
import projectsIcon from "../../assets/icons/project.png";
import departmentIcon from "../../assets/icons/department.png";
import discountIcon from "../../assets/icons/discount.png";
import bannerIcon from "../../assets/icons/banner.png";
import trainingIcon from "../../assets/icons/training.png";
import assignmentIcon from "../../assets/icons/assignment.png";
import testimonialIcon from "../../assets/icons/testimonial.png";

import headerImg from "../../assets/images/header.jpg";
const drawerWidth = 230;

const CustomListButton = styled(ListItemButton)(({ theme }) => ({
    // boxSizing: "border-box",
    // width: "230px",
    "& .MuiListItemIcon-root": {
        minWidth: "45px",
    },
    "&.Mui-selected": {
        // borderRadius: "8px",
        // color: "white",
        // paddingLeft: "10px",
        // boxSizing: "border-box",
        // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
        // borderLeft: "2px solid #7f85f5",
        backgroundColor: "#001f45 !important",
        "& .MuiListItemIcon-root": {
            // transform: "scale(1.2)",
            // color: "#FFD700", // Gold color for selected icons
            // "& .MuiSvgIcon-root": {
            //     padding: 4,
            //     // border: "1px solid white",
            //     borderRadius: 4,
            // },
        },
    },
    "&:hover": {
        // paddingLeft: "14px",
        // borderLeft: "2px solid #7f85f5",
        "& .MuiListItemIcon-root": {
            // transform: "scale(1.2)",
            // color: "#FFD700", // Gold color for selected icons
            // "& .MuiSvgIcon-root": {
            //     backgroundColor: "#242424 !important",
            //     color: "#7f85f5",
            //     padding: 4,
            //     fontSize: "32px",
            //     // border: "1px solid white",
            //     borderRadius: 4,
            // },
        },
    },
}));

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    // minWidth: "40px",
    color: "white", // Default color for icons
    transition: theme.transitions.create(["transform", "color"], {
        duration: theme.transitions.duration.short,
    }),
    "&:hover": {
        color: "#87CEEB", // Sky blue hover effect
    },
}));

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function MiniDrawer() {
    // extract location url

    const theme = useTheme();
    const [role, setRole] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState();
    const navigate = useNavigate();

    React.useEffect(() => {
        const location = window.location.pathname;
        setSelectedIndex(Number.parseInt(location.split("tab=")[1]));
    }, []);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const handleClick = () => {
        setOpen(!open);
    };

    React.useEffect(() => {
        let role = Cookies.get("role");
        setRole(role);
    });

    const handleLogout = () => {
        Cookies.remove("token");
        window.location.href = "/admin-login";
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar sx={{ backgroundColor: "#00010e" }} position='fixed' open={open}>
                <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        onClick={handleDrawerOpen}
                        edge='start'
                        sx={{
                            ...(open && { display: "none" }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography color='#7f85f5' variant='h6' noWrap component='div'>
                        TECHLOGICS | ADMINISTRATOR
                    </Typography>
                    <IconButton color='inherit' onClick={handleLogout}>
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Drawer variant='permanent' sx={{ backgroundColor: "#00010e", borderRight: "none" }} open={open}>
                <DrawerHeader sx={{ backgroundColor: "#00010e" }}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <ChevronRightIcon sx={{ backgroundColor: "white" }} />
                        ) : (
                            <ChevronLeftIcon sx={{ backgroundColor: "white" }} />
                        )}
                    </IconButton>
                </DrawerHeader>
                {/* <Divider /> */}
                <List sx={{ pt: 0, width: "100%", height: "100vh", maxWidth: 360, bgcolor: "#00010e" }} component='nav' aria-labelledby='nested-list-subheader'>
                    <>
                        <Tooltip title='Dashboard' placement='right'>
                            <CustomListButton
                                onClickCapture={handleDrawerClose}
                                selected={selectedIndex === 0}
                                component={Link}
                                to='dashboard/tab=0'
                                onClick={(event) => handleListItemClick(event, 0)}
                            >
                                <CustomListItemIcon>
                                    <img width={22} src={dashboardIcon} alt='Dashboard' />
                                    {/* <DashboardOutlinedIcon
                                        sx={{ fontSize: selectedIndex === 0 ? "32px" : "26px", color: selectedIndex === 0 ? "#7f85f5" : "white" }}
                                        /> */}
                                </CustomListItemIcon>
                                <ListItemText primary='Dashboard' sx={{ color: selectedIndex === 0 ? "#7f85f5" : "white" }} />
                            </CustomListButton>
                        </Tooltip>
                        <Tooltip title='Departments' placement='right'>
                            <CustomListButton
                                onClickCapture={handleDrawerClose}
                                selected={selectedIndex === 1}
                                component={Link}
                                to='departments/tab=1'
                                onClick={(event) => handleListItemClick(event, 1)}
                            >
                                <ListItemIcon>
                                    <img width={22} src={departmentIcon} alt='Departments' />
                                    {/* <BusinessIcon sx={{ fontSize: selectedIndex === 1 ? "32px" : "26px", color: selectedIndex === 1 ? "#7f85f5" : "white" }} /> */}
                                </ListItemIcon>
                                <ListItemText primary='Departments' sx={{ color: selectedIndex === 1 ? "#7f85f5" : "white" }} />
                            </CustomListButton>
                        </Tooltip>
                        <Tooltip title='Projects' placement='right'>
                            <CustomListButton
                                onClickCapture={handleDrawerClose}
                                selected={selectedIndex === 2}
                                component={Link}
                                to='projects/tab=2'
                                onClick={(event) => handleListItemClick(event, 2)}
                            >
                                <ListItemIcon>
                                    <img width={22} src={projectsIcon} alt='Projects' />
                                    {/* <AppsIcon sx={{ fontSize: selectedIndex === 2 ? "32px" : "26px", color: selectedIndex === 2 ? "#7f85f5" : "white" }} /> */}
                                </ListItemIcon>
                                <ListItemText primary='Projects' sx={{ color: selectedIndex === 2 ? "#7f85f5" : "white" }} />
                            </CustomListButton>
                        </Tooltip>
                        <Tooltip title='Discount' placement='right'>
                            <CustomListButton
                                onClickCapture={handleDrawerClose}
                                selected={selectedIndex === 3}
                                component={Link}
                                to='discount/tab=3'
                                onClick={(event) => handleListItemClick(event, 3)}
                            >
                                <ListItemIcon>
                                    <img width={22} src={discountIcon} alt='Discounts' />
                                    {/* <DiscountOutlinedIcon
                                        sx={{ fontSize: selectedIndex === 3 ? "32px" : "26px", color: selectedIndex === 3 ? "#7f85f5" : "white" }}
                                        /> */}
                                </ListItemIcon>
                                <ListItemText primary='Discounts' sx={{ color: selectedIndex === 3 ? "#7f85f5" : "white" }} />
                            </CustomListButton>
                        </Tooltip>
                        <Tooltip title='Banners' placement='right'>
                            <CustomListButton
                                onClickCapture={handleDrawerClose}
                                selected={selectedIndex === 4}
                                component={Link}
                                to='banners/tab=4'
                                onClick={(event) => handleListItemClick(event, 4)}
                            >
                                <ListItemIcon>
                                    <img width={22} src={bannerIcon} alt='Banners' />
                                    {/* <ViewCarouselOutlinedIcon
                                        sx={{ fontSize: selectedIndex === 4 ? "32px" : "26px", color: selectedIndex === 4 ? "#7f85f5" : "white" }}
                                        /> */}
                                </ListItemIcon>
                                <ListItemText primary='Banners' sx={{ color: selectedIndex === 4 ? "#7f85f5" : "white" }} />
                            </CustomListButton>
                        </Tooltip>
                        <Tooltip title='Trainings' placement='right'>
                            <CustomListButton
                                onClickCapture={handleDrawerClose}
                                selected={selectedIndex === 5}
                                component={Link}
                                to='trainings/tab=5'
                                onClick={(event) => handleListItemClick(event, 5)}
                            >
                                <ListItemIcon>
                                    <img width={22} src={trainingIcon} alt='Trainings' />
                                    {/* <LocalLibraryOutlinedIcon
                                        sx={{ fontSize: selectedIndex === 5 ? "32px" : "26px", color: selectedIndex === 5 ? "#7f85f5" : "white" }}
                                        /> */}
                                </ListItemIcon>
                                <ListItemText primary='Trainings' sx={{ color: selectedIndex === 5 ? "#7f85f5" : "white" }} />
                            </CustomListButton>
                        </Tooltip>
                        <Tooltip title='Assignments' placement='right'>
                            <CustomListButton
                                onClickCapture={handleDrawerClose}
                                selected={selectedIndex === 6}
                                component={Link}
                                to='assignments/tab=6'
                                onClick={(event) => handleListItemClick(event, 6)}
                            >
                                <ListItemIcon>
                                    <img width={22} src={assignmentIcon} alt='Assignments' />
                                    {/* <AssignmentOutlinedIcon
                                        sx={{ fontSize: selectedIndex === 6 ? "32px" : "26px", color: selectedIndex === 6 ? "#7f85f5" : "white" }}
                                        /> */}
                                </ListItemIcon>
                                <ListItemText primary='Assignments' sx={{ color: selectedIndex === 6 ? "#7f85f5" : "white" }} />
                            </CustomListButton>
                        </Tooltip>
                        <Tooltip title='Testimonials' placement='right'>
                            <CustomListButton
                                onClickCapture={handleDrawerClose}
                                selected={selectedIndex === 7}
                                component={Link}
                                to='testimonials/tab=7'
                                onClick={(event) => handleListItemClick(event, 7)}
                            >
                                <ListItemIcon>
                                    <img width={22} src={testimonialIcon} alt='Testimonials' />
                                    {/* <RateReviewOutlinedIcon
                                        sx={{ fontSize: selectedIndex === 7 ? "32px" : "26px", color: selectedIndex === 7 ? "#7f85f5" : "white" }}
                                    /> */}
                                </ListItemIcon>
                                <ListItemText primary='Testimonials' sx={{ color: selectedIndex === 7 ? "#7f85f5" : "white" }} />
                            </CustomListButton>
                        </Tooltip>
                    </>
                </List>
            </Drawer>

            <Box component='div' className='outlet' sx={{ flexGrow: 1 }}>
                <DrawerHeader />
                <div className='outlet-w'>
                    <Outlet />
                </div>
                {/* <Footer /> */}
            </Box>
        </Box>
    );
}
