import axiosInstance from "../config/axiosConfig";
import Cookies from "js-cookie";

export const getBanner = () => {
    try {
        return axiosInstance.get("/api/banner/active");
    } catch (error) {
        throw error;
    }
};
export const getBanners = () => {
    try {
        return axiosInstance.get("/api/banner");
    } catch (error) {
        throw error;
    }
};

export const getBannerById = (id) => {
    try {
        return axiosInstance.get(`/api/banner/${id}`);
    } catch (error) {
        throw error;
    }
};

export const addBanner = (body) => {
    let token = Cookies.get("token");
    try {
        return axiosInstance.post("/api/banner", body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        throw error;
    }
};

export const updateBanner = (id, body) => {
    let token = Cookies.get("token");
    try {
        return axiosInstance.put(`/api/banner/${id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        throw error;
    }
};
export const deleteBanner = (id) => {
    let token = Cookies.get("token");
    try {
        return axiosInstance.delete(`/api/banner/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        throw error;
    }
};
