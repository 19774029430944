import * as React from "react";
import { SpeedDial, SpeedDialIcon, SpeedDialAction, Box, List, ListItemButton, ListItemIcon, styled, Fab, Stack, Tooltip } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";
import whatsappImg from "../../assets/icons/whatsapp.png";
import youtubeImg from "../../assets/icons/youtube.png";
import instagramImg from "../../assets/icons/instagram.png";
import facebookImg from "../../assets/icons/facebook.png";
const CustomListButton = styled(ListItemButton)(({ theme }) => ({
    // boxSizing: "border-box",
    // width: "230px",
    padding: "10px 10px",
    "& .MuiListItemIcon-root": {
        minWidth: "5px",
    },
}));

export default function BottomRightSpeedDial() {
    const actions = [
        { icon: <img src={youtubeImg} width={22} />, bg: "#d32f2f", name: "YouTube", link: "https://youtube.com/@techlogics552?si=n3Kj35lp_TUhbcdA" },
        {
            icon: <img src={instagramImg} width={22} />,
            bg: "#f30097",
            name: "Instagram",
            link: "https://www.instagram.com/techlogics39/",
        },
        { icon: <img src={whatsappImg} width={22} />, bg: "#2e7d32", name: "WhatsApp", link: "https://wa.me/9133181444" },
        {
            icon: <img src={facebookImg} width={22} />,
            bg: "#1976d2",
            name: "Facebook",
            link: "https://www.facebook.com/profile.php?id=61567901265819",
        },
    ];

    return (
        // <SpeedDial
        //     ariaLabel='SpeedDial example'
        //     sx={{
        //         position: "fixed",
        //         bottom: 20,
        //         right: 20   ,
        //         "& .MuiFab-primary": {
        //             backgroundColor: "#e5057f", // Change SpeedDial button color
        //             // color: "#aaa", // Change SpeedDial icon color
        //         },
        //         "& .MuiSpeedDialIcon-icon": {
        //             color: "#fff", // Change the inner SpeedDial icon color
        //         },
        //     }}
        //     icon={<SpeedDialIcon  />}
        // >
        //     {actions.map((action) => (
        //         <SpeedDialAction
        //             sx={{ pt: 1 }}
        //             key={action.name}
        //             icon={
        //                 action.link ? (
        //                     <Link to={action.link} target='_blank' rel='noopener noreferrer'>
        //                         {action.icon}
        //                     </Link>
        //                 ) : (
        //                     action.icon
        //                 )
        //             }
        //             tooltipTitle={action.name}
        //         />
        //     ))}
        // </SpeedDial>
        <Box
            sx={{
                position: "fixed",
                top: "40%",
                right: 8,
                zIndex: 1000,
                // backgroundColor: "white",
            }}
        >
            <Stack direction={"column"} spacing={1}>
                {actions.map((action, key) => (
                    <Tooltip key={key} title={action.name} placement='left'>
                        <Fab size='small' sx={{ pt: 1, backgroundColor: "white", border: `1px solid ${action.bg}` }}>
                            <Link to={action.link} target='_blank' rel='noopener noreferrer'>
                                {action.icon}
                            </Link>
                        </Fab>
                    </Tooltip>
                ))}
            </Stack>
        </Box>
    );
}
