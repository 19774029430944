import React, { useEffect } from "react";
import MiniDrawer from "../common/sidebar";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import { isLogin } from "../../services/authService";

export default function AdminHome() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Techlogics | Admin";
        if (!isLogin) navigate("/admin-login");
    }, []);
    return <MiniDrawer />;
}
