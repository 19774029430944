import { toast } from "react-toastify";
import * as React from "react";
import { Box, Button, Dialog, Divider, AppBar, Toolbar, IconButton, Typography, OutlinedInput, InputAdornment, DialogContent, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import scannerImg from "../../assets/images/scanner.jpg";
import * as yup from "yup";
import { addPayment } from "../../services/paymentService";
import { isMobile } from "react-device-detect";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const schema = yup.object().shape({
    name: yup.string().required("Required"),
    email: yup.string().email("Invalid email").required("Required"),
    mobile: yup.string().length(10, "Must be 10 digits").required("Required"),
    projectCode: yup.string().required("Required"),
});

export default function PaymentDialog({ open, handleClose, project }) {
    const [errors, setErrors] = React.useState({});
    const [form, setForm] = React.useState({
        name: "",
        email: "",
        mobile: "",
        projectCode: project.project_code,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = () => {
        schema
            .validate(form, { abortEarly: false })
            .then(() => {
                addPayment(form)
                    .then((res) => {
                        setErrors({});
                        toast.success(res.data.message);
                        setForm({
                            name: "",
                            email: "",
                            mobile: "",
                            projectCode: project.project_code,
                        });
                        setTimeout(() => handleClose(), 2000);
                    })
                    .catch(() => toast.error("Something went wrong"));
            })
            .catch((err) => {
                toast.error("Please fill all the fields");
                const newErrors = {};
                err.inner.forEach((error) => (newErrors[error.path] = error.message));
                setErrors(newErrors);
            });
    };

    const getFinalPrice = (project) => {
        if (project.discountType === "percentage") {
            return Number.parseFloat(project.price - (project.price * project.discountPrice) / 100).toFixed(1);
        } else {
            return project.price - project.discountPrice;
        }
    };

    return (
        <Dialog fullScreen maxWidth='md' open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: "relative" }} color=''>
                <Toolbar>
                    <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant='h6'>
                        Payment Details
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent sx={{ p: 0 }}>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: 2 }}>
                    <Box className='payment-banner'></Box>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <img src={scannerImg} alt='scanner' width={250} />
                    </Box>
                    <Box p={2}>
                        <Typography mb={2} variant='h6' textAlign={{ xs: "center" }} color='#e5057f'>
                            Fill the form after payment
                        </Typography>
                        {["name", "email", "mobile", "projectCode"].map((field, index) => (
                            <OutlinedInput
                                key={index}
                                sx={{
                                    mb: 2,
                                    bgcolor: "#fff",
                                    "&:hover": { bgcolor: "#fafafa" },
                                }}
                                fullWidth
                                name={field}
                                type={field === "email" ? "email" : field === "mobile" ? "number" : "text"}
                                value={form[field]}
                                onChange={handleChange}
                                readOnly={field === "projectCode"}
                                size='small'
                                error={!!errors[field]}
                                startAdornment={
                                    <InputAdornment position='start'>
                                        <Typography width={100} variant='body1' color='text.secondary'>
                                            {field.charAt(0).toUpperCase() + field.slice(1)}
                                        </Typography>
                                        <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    errors[field] && (
                                        <InputAdornment position='end'>
                                            <Typography variant='caption' color='error'>
                                                {errors[field]}
                                            </Typography>
                                        </InputAdornment>
                                    )
                                }
                            />
                        ))}
                        <Button
                            fullWidth
                            variant='contained'
                            onClick={handleSubmit}
                            sx={{
                                py: 1.5,
                                bgcolor: "#e5057f",
                                fontSize: "1.1rem",
                            }}
                        >
                            {/* Confirm payment */}
                            Confirm payment of Rs. {getFinalPrice(project)}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
