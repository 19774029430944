import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Container, Typography, useMediaQuery } from "@mui/material";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { ReactTyped } from "react-typed";
import axiosInstance from "../../config/axiosConfig";
import WhatWeOfferCarousel from "./whatWeOffer";
import ProjectCardCarousal from "./projectCardCarousal";
import Header from "../header/header";
import Footer from "../footer/footer";
import SmoothBanner from "./smoothBanner";
import gsap from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger"; // Import ScrollTrigger plugin
import FadeInOnScroll from "./smoothBanner";
import Testimonials from "./testimonials";
import { getTestimonials } from "../../services/testimonialService";

gsap.registerPlugin(ScrollTrigger);

function Home() {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [projects, setProjects] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const whatWeOfferRef = useRef(null);
    const whyChooseUsRef = useRef(null);
    const projectCardRef = useRef(null);
    const bannerRef = useRef(null);

    useEffect(() => {
        axiosInstance.get("/api/project").then((res) => {
            setProjects(res.data.data);
        });

        getTestimonials()
            .then((res) => {
                setTestimonials(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });

        gsap.fromTo(
            whyChooseUsRef.current,
            { opacity: 0, y: 50 }, // Start from invisible and below
            {
                opacity: 1,
                y: 0,
                duration: 1.5,
                // ease: "power2.out",
                scrollTrigger: {
                    trigger: whyChooseUsRef.current,
                    start: "top 85%", // Trigger when the element is 80% visible from the top
                    end: "bottom 50%", // End when the element is 20% from the bottom
                    toggleActions: "play none none reverse", // Play the animation when it comes into view
                    // markers: true,
                },
            }
        );

        gsap.fromTo(
            whatWeOfferRef.current,
            { opacity: 0, y: 100 }, // Start from invisible and below
            {
                opacity: 1,
                y: 0,
                duration: 1,
                // ease: "power2.out",
                scrollTrigger: {
                    trigger: whatWeOfferRef.current,
                    start: "top 90%", // Trigger when the element is 80% visible from the top
                    end: "bottom 50%", // End when the element is 20% from the bottom
                    toggleActions: "play none none reverse", // Play the animation when it comes into view
                    // markers: true,
                },
            }
        );

        gsap.fromTo(
            projectCardRef.current,
            { opacity: 0, y: 50 }, // Start from invisible and below
            {
                opacity: 1,
                y: 0,
                duration: 1.5,
                // ease: "power2.out",
                scrollTrigger: {
                    trigger: projectCardRef.current,
                    start: "top 85%", // Trigger when the element is 80% visible from the top
                    end: "bottom 50%", // End when the element is 20% from the bottom
                    toggleActions: "play none none reverse", // Play the animation when it comes into view
                    // markers: true,
                },
            }
        );

        const widgetContainer = document.getElementById("trustindex-widget");
        if (widgetContainer) {
            const script = document.createElement("script");
            script.src = "https://cdn.trustindex.io/loader.js?adf17fc365cf751683061c8a6a6"; // Trustindex widget script URL
            script.async = true;
            widgetContainer.appendChild(script);
        }

        return () => {
            const widgetContainer = document.getElementById("trustindex-widget");
            if (widgetContainer) {
                widgetContainer.innerHTML = ""; // Clean innerHTML if element exists
            }
        };
    }, [location.pathname]);

    return (
        <div>
            <Header />

            <Container maxWidth='xl' sx={{ height: { xs: "55vh", sm: "90vh" } }} ref={bannerRef} className='banner'>
                <Box p={isMobile ? 3 : 5} className='text'>
                    <Typography variant={isMobile ? "h6" : "h4"} sx={{ mb: 2, color: "#011936" }}>
                        <ReactTyped
                            onComplete={(self) => self.cursor.remove()}
                            strings={['<span style="color: #e5057f; fontWeight: bolder">Unlock</span> your Potential with our Expertise']}
                            typeSpeed={10}
                        />
                    </Typography>

                    <Typography variant={isMobile ? "body1" : "h6"} sx={{ mb: 3, color: "#242f62" }}>
                        <ReactTyped
                            onComplete={(self) => self.cursor.remove()}
                            strings={["We bridge the gap between Knowledge and Opportunity"]}
                            typeSpeed={10}
                        />
                    </Typography>
                    <Button onClick={() => navigate("/projects")} variant='outlined' color='primary' size='small'>
                        Explore Projects
                    </Button>
                </Box>
            </Container>

            <Container maxWidth='xl' sx={{ padding: { sm: "60px 30px", xs: "40px 20px" } }}>
                <Typography variant={isMobile ? "h5" : "h4"} color='#242f62' align='center' gutterBottom>
                    Why Choose Us?
                </Typography>
                <Typography px={4} variant='body1' color='textPrimary' align='justify' sx={{ margin: "0 auto" }}>
                    At Techlogics, we specialize in providing high-quality, affordable academic solutions to help students, researchers, and professionals
                    achieve their goals. Whether you need assistance with academic projects, dissertations, publications, assignments, or are looking for
                    training opportunities, we have you covered.
                </Typography>
            </Container>

            <Container maxWidth='xl' ref={whatWeOfferRef} sx={{ padding: "40px 20px", backgroundColor: "#f0f4ff" }}>
                <WhatWeOfferCarousel />
            </Container>

            <Container maxWidth='xl' ref={projectCardRef} sx={{ padding: "20px 20px" }}>
                <ProjectCardCarousal projects={projects} />
            </Container>

            <Container className='testimonial-banner' maxWidth='xl' sx={{ padding: "40px 20px", backgroundColor: "#f0f4ff" }}>
                <Testimonials testimonials={testimonials} />
            </Container>
            <Footer />
        </div>
    );
}

export default Home;
