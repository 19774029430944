import { Box, Button, Divider, IconButton, InputAdornment, Typography, OutlinedInput, Stack, Switch, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { addTestimonial, deleteTestimonial, getTestimonialById, updateTestimonial } from "../../../services/testimonialService";
import Loader from "../../common/loader";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import testimonialSchema from "./schema";
import ActionDialog from "../../common/actionDialog";

export default function AddTestimonial({ mode }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [checked, setChecked] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [errors, setErrors] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [testimonial, setTestimonial] = React.useState({
        name: "",
        rating: "",
        message: "",
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState({
        title: "",
        content: "",
        onConfirm: null,
        onCancel: null,
        mode: "",
    });

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        setErrors({});
        setChecked(false);
        if (mode === "view") {
            getTestimonialById(id)
                .then((res) => {
                    setTestimonial(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (mode === "add") {
            setTestimonial({
                name: "",
                rating: "",
                message: "",
            });
        }
    }, [id, isLoading]);

    // Save Testimonial function with Yup validation
    const saveTestimonial = () => {
        setErrors({}); // Clear previous errors
        testimonialSchema
            .validate(testimonial, { abortEarly: false })
            .then((validatedData) => {
                if (mode === "add") {
                    setIsLoading(true);
                    setMessage("Saving testimonial");
                    addTestimonial(validatedData)
                        .then((res) => {
                            toast.success("Testimonial added");
                            setTimeout(() => {
                                setIsLoading(false);
                                window.location.href = "/admin/testimonials/tab=7";
                            }, 3000);
                        })
                        .catch((err) => {
                            toast.error(err.response.data.message || "Error adding testimonial");
                            console.log(err);
                            setIsLoading(false);
                        });
                } else if (mode === "view") {
                    setDialogData({
                        mode: "update",
                        title: "Confirm Update",
                        content: `Do you want to update?`,
                        onConfirm: () => {
                            setIsLoading(true);
                            setMessage("Updating testimonial");
                            updateTestimonial(testimonial.id, validatedData)
                                .then((res) => {
                                    toast.success("Testimonial updated");
                                    setDialogOpen(false);

                                    setTimeout(() => {
                                        setIsLoading(false);
                                        window.location.href = "/admin/testimonials/tab=7";
                                    }, 3000);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    toast.error(err.response.data.message || "Error updating testimonial");
                                    setIsLoading(false);
                                });
                        },
                        onClose: () => {
                            setDialogOpen(false);
                            setIsLoading(false);
                        },
                    });
                    setDialogOpen(true);
                }
            })
            .catch((validationErrors) => {
                // Map Yup validation errors to state
                const formattedErrors = {};
                validationErrors.inner.forEach((error) => {
                    formattedErrors[error.path] = error.message;
                });
                setErrors(formattedErrors);
            });
    };

    const handleDeleteDepartment = () => {
        setDialogData({
            mode: "delete",
            title: "Confirm Deletion",
            content: `Are you sure you want to delete? This action cannot be undone`,
            onConfirm: () => {
                setIsLoading(true);
                setMessage("Deleting testimonial");
                deleteTestimonial(testimonial.id)
                    .then((res) => {
                        toast.success("Testimonial deleted");
                        setDialogOpen(false);
                        setTimeout(() => {
                            setIsLoading(false);
                            window.location.href = "/admin/testimonials/tab=7";
                        }, 3000);
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error(err.response.data.message || "Error deleting testimonial");
                        setIsLoading(false);
                    });
            },
            onClose: () => {
                setDialogOpen(false);
                setIsLoading(false);
            },
        });
        setDialogOpen(true);
    };

    return (
        <Box>
            <ActionDialog
                mode={dialogData.mode}
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    setIsLoading(false);
                }}
                title={dialogData.title}
                content={dialogData.content}
                onConfirm={dialogData.onConfirm}
            />
            {isLoading && <Loader message={message} />}
            {!isLoading && (
                <>
                    {mode === "add" && (
                        <Typography className='purple' variant='h6'>
                            Add Testimonial
                        </Typography>
                    )}
                    {mode === "view" && (
                        <>
                            <Typography className='purple' variant='h6'>
                                Testimonial details
                            </Typography>
                            <FormControlLabel
                                value='end'
                                control={<Switch checked={checked} onChange={handleChange} color='primary' />}
                                label='Edit mode'
                                labelPlacement='end'
                            />
                        </>
                    )}
                    <Box py={1}>
                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position='start'>
                                    <Typography width={75} variant='body1'>
                                        Name
                                    </Typography>
                                    <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                </InputAdornment>
                            }
                            sx={{ mb: 2 }}
                            fullWidth
                            readOnly={mode === "view" && !checked}
                            value={testimonial.name}
                            onChange={(e) =>
                                setTestimonial({
                                    ...testimonial,
                                    name: e.target.value,
                                })
                            }
                            size='small'
                            variant='outlined'
                            error={!!errors?.name} // Display error if exists
                            endAdornment={
                                errors?.name && (
                                    <InputAdornment position='end'>
                                        <Typography variant='caption' color='error'>
                                            {errors?.name}
                                        </Typography>
                                    </InputAdornment>
                                )
                            }
                        />

                        {/* Name Field */}
                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position='start'>
                                    <Typography width={75} variant='body1'>
                                        Rating
                                    </Typography>
                                    <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                </InputAdornment>
                            }
                            fullWidth
                            readOnly={mode === "view" && !checked}
                            sx={{ mb: 2 }}
                            value={testimonial.rating}
                            onChange={(e) =>
                                setTestimonial({
                                    ...testimonial,
                                    rating: e.target.value,
                                })
                            }
                            size='small'
                            variant='outlined'
                            error={!!errors?.rating} // Display error if exists
                            endAdornment={
                                errors?.rating && (
                                    <InputAdornment position='end'>
                                        <Typography variant='caption' color='error'>
                                            {errors?.rating}
                                        </Typography>
                                    </InputAdornment>
                                )
                            }
                        />

                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position='start'>
                                    <Typography width={75} variant='body1'>
                                        Feedback
                                    </Typography>
                                    <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                </InputAdornment>
                            }
                            multiline
                            fullWidth
                            readOnly={mode === "view" && !checked}
                            sx={{ mb: 2 }}
                            value={testimonial.message}
                            onChange={(e) =>
                                setTestimonial({
                                    ...testimonial,
                                    message: e.target.value,
                                })
                            }
                            size='small'
                            variant='outlined'
                            error={!!errors?.message} // Display error if exists
                            endAdornment={
                                errors?.message && (
                                    <InputAdornment position='end'>
                                        <Typography variant='caption' color='error'>
                                            {errors?.message}
                                        </Typography>
                                    </InputAdornment>
                                )
                            }
                            rows={10}
                        />

                        <Stack direction={"row"} gap={1}>
                            {mode === "add" && (
                                <Button sx={{ backgroundColor: "#30369e" }} variant='contained' onClick={saveTestimonial}>
                                    Save Testimonial
                                </Button>
                            )}
                            {checked && (
                                <Button variant='outlined' sx={{ color: "#30369e" }} onClick={saveTestimonial}>
                                    update Testimonial
                                </Button>
                            )}
                            {mode === "view" && (
                                <Button variant='contained' color='error' onClick={handleDeleteDepartment}>
                                    delete
                                </Button>
                            )}
                        </Stack>
                    </Box>
                </>
            )}
        </Box>
    );
}
