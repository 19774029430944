import { useEffect, useState } from "react";
import { Box, Button, Divider, InputAdornment, MenuItem, OutlinedInput, Select, Stack, Typography } from "@mui/material";
import React from "react";
import { red } from "@mui/material/colors";
import { isMobile } from "react-device-detect";
import { addDiscount, getDiscount } from "../../../services/discountService";
import { toast } from "react-toastify";
export default function Discount() {
    const [price, setPrice] = React.useState("");
    const [final, setFinal] = React.useState("");
    const [present, setPresent] = React.useState(false);
    const [discountPrice, setDiscountPrice] = React.useState("");
    const [discountType, setDiscountType] = React.useState("percentage");

    useEffect(() => {
        document.title = "Techlogics | Discounts";
        getDiscount()
            .then((res) => {
                const { actualPrice, discountPrice, discountType } = res.data.data;
                setPrice(actualPrice);
                setDiscountType(discountType);
                setDiscountPrice(discountPrice);
                if (discountPrice) setPresent(true);
                if (discountType === "percentage") {
                    setFinal(Number.parseFloat(actualPrice - (actualPrice * discountPrice) / 100).toFixed(1));
                } else {
                    setFinal(actualPrice - discountPrice);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleAddDiscount = () => {
        console.log(price, discountPrice, discountType);
        if (discountType === "percentage") {
            if (Number.parseInt(discountPrice) > 100) {
                toast.error("Discount cannot be greater than 100%");
                return;
            }
        } else {
            if (Number.parseInt(discountPrice) > price) {
                toast.error("Discount cannot be greater than actual price");
                return;
            }
        }

        addDiscount({ discountPrice, discountType })
            .then((res) => {
                toast.success("Discount added successfully");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error adding discount");
            });
    };

    const clearDiscount = () => {
        addDiscount({ discountPrice: "", discountType: "" })
            .then((res) => {
                console.log(res);
                toast.success("Discount cleared successfully");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error clearing discount");
            });
    };

    return (
        <Box p={2}>
            <Typography className='purple' variant='h6'>
                Manage Discounts
            </Typography>
            <Typography variant='body1' color='text.secondary'>
                Actual price for all projects: <b>₹ {Number.parseFloat(price).toFixed(2)}</b>
            </Typography>
            <Typography variant='body1' color='text.secondary'>
                Current price after discount: <b>₹ {Number.parseFloat(final).toFixed(2)}</b>
            </Typography>

            <Stack direction={"column"} maxWidth={isMobile ? "100%" : 500}>
                <OutlinedInput
                    sx={{ mt: 2, mb: 2, pr: 0 }}
                    size='small'
                    type='number'
                    value={discountPrice}
                    onChange={(e) => setDiscountPrice(e.target.value)}
                    startAdornment={
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Typography variant='body1' color='text.secondary'>
                                Discount
                            </Typography>
                            <Divider orientation='vertical' sx={{ mr: 1 }} flexItem />
                        </Box>
                    }
                    endAdornment={
                        <InputAdornment position='end'>
                            <Select
                                color='text.secondary'
                                sx={{ width: 160, borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                                size='small'
                                value={discountType}
                                onChange={(e) => setDiscountType(e.target.value)}
                            >
                                <MenuItem color='text.secondary' value='percentage'>
                                    % (Percentage)
                                </MenuItem>
                                <MenuItem value='amount'>₹ (Rupees)</MenuItem>
                            </Select>
                        </InputAdornment>
                    }
                />
                <Stack direction={"row"} gap={2}>
                    <Button variant='outlined' sx={{ color: "#30369e" }} color='' onClick={handleAddDiscount} disabled={!discountPrice || !discountType}>
                        Apply Discount
                    </Button>
                    {present && (
                        <Button variant='contained' color='error' onClick={clearDiscount}>
                            Clear all Discounts
                        </Button>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
}
