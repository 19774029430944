import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const FadeInOnScroll = () => {
    const elementRef = useRef(null);

    useEffect(() => {
        // Create the fade-in animation on scroll
        gsap.fromTo(
            elementRef.current,
            { opacity: 0, y: 50 },
            {
                opacity: 1,
                y: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: elementRef.current,
                    start: "top 80%", // Start when top of element hits 80% of viewport
                    end: "top 50%", // End when top of element hits 50% of viewport
                    toggleActions: "play none none reverse", // Play on enter, reverse on leave
                    scrub: true, // Smooth fade based on scroll
                    // markers: true, // Show markers for debugging
                },
            }
        );
    }, []);

    return (
        <div ref={elementRef} style={{ height: "auto", padding: "50px", backgroundColor: "#f0f4ff" }}>
            <h2>Scroll to see the fade-in animation</h2>
            <p>This content will fade in on scroll and reverse when you scroll back up.</p>
        </div>
    );
};

export default FadeInOnScroll;
