import React, { useEffect, useState } from "react";
import { Button, Box, useMediaQuery, Typography, InputAdornment, OutlinedInput, Divider, FormControlLabel, Switch, Stack } from "@mui/material";
import * as yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useParams } from "react-router";
import { getDepartments, getDepartmentByCode } from "../../../services/departmentService";
import { getProjectById, addProject, updateProject, deleteProject } from "../../../services/projectService";
import { useTheme } from "@emotion/react";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ArticleIcon from "@mui/icons-material/Article";
import Loader from "../../common/loader";
import { toast } from "react-toastify";
import { addTraining, deleteTraining, getTraining, updateTraining } from "../../../services/trainingService";
import ActionDialog from "../../common/actionDialog";

const trainingSchema = yup.object().shape({
    title: yup.string().required("Required"),
    subTitle: yup.string().required("Required"),
    description: yup.string().required("Required"),
    youtubeLink: yup.string().url().required("Required"),
    documentLink: yup.string().url().required("Required"),
    price: yup.number().required("Required").min(0, "Price must be a positive number"),
    category: yup.string().required("Required"),
});

const AddTraining = ({ mode }) => {
    const { id } = useParams();
    const [checked, setChecked] = React.useState(false);
    const [message, setMessage] = useState("");

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState({
        title: "",
        content: "",
        onConfirm: null,
        onCancel: null,
        mode: "",
    });

    const [technologies, setTechnologies] = useState([]);
    const [formData, setFormData] = useState({
        title: "",
        subTitle: "",
        description: "",
        youtubeLink: "",
        documentLink: "",
        price: "",
        category: "",
    });

    const handleSwitchChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        setErrors({});
        setChecked(false);

        if (mode === "add") {
            setFormData({
                title: "",
                subTitle: "",
                description: "",
                youtubeLink: "",
                documentLink: "",
                price: "",
                category: "",
            });
        } else if (mode === "view") {
            getTraining(id)
                .then((res) => {
                    setFormData(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [id]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile view

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        trainingSchema
            .validate(formData, { abortEarly: false })
            .then(() => {
                setErrors({});
                if (mode === "add") {
                    setIsLoading(true);
                    setMessage("Saving training");
                    addTraining(formData)
                        .then(() => {
                            toast.success("Training added");
                            setTimeout(() => {
                                setIsLoading(false);
                                window.location.href = "/admin/trainings/tab=5";
                            }, 1500);
                        })
                        .catch((err) => {
                            console.log(err);
                            toast.error(err.response.data.message || "Failed to add training");
                            setIsLoading(false);
                        });
                } else if (mode === "view") {
                    setDialogData({
                        mode: "update",
                        title: "Confirm Update",
                        content: `Do you want to update?`,
                        onConfirm: () => {
                            setIsLoading(true);
                            setMessage("Updating training");
                            updateTraining(id, formData)
                                .then(() => {
                                    toast.success("Training updated");
                                    setDialogOpen(false);
                                    setTimeout(() => {
                                        setIsLoading(false);
                                        window.location.href = "/admin/trainings/tab=5";
                                    }, 1500);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    toast.error(err.response.data.message || "Failed to update training");
                                    setIsLoading(false);
                                });
                        },
                        onClose: () => {
                            setDialogOpen(false);
                            setIsLoading(false);
                        },
                    });
                    setDialogOpen(true);
                }
            })
            .catch((validationErrors) => {
                const validationErrorMap = {};
                validationErrors.inner.forEach((error) => {
                    validationErrorMap[error.path] = error.message;
                });

                setErrors(validationErrorMap);
            });
    };

    const handleDeleteProject = () => {
        setDialogData({
            mode: "delete",
            title: "Confirm Deletion",
            content: `Are you sure you want to delete? This action cannot be undone`,
            onConfirm: () => {
                setDialogOpen(false);
                setIsLoading(true);
                setMessage("Deleting training");
                deleteTraining(id)
                    .then(() => {
                        toast.success("Training deleted");
                        setTimeout(() => {
                            setIsLoading(false);
                            window.location.href = "/admin/trainings/tab=5";
                        }, 1500);
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error(err.response.data.message || "Failed to delete training");
                        setIsLoading(false);
                    });
            },
            onClose: () => {
                setDialogOpen(false);
                setIsLoading(false);
            },
        });
        setDialogOpen(true);
    };

    return (
        <Box>
            <ActionDialog
                mode={dialogData.mode}
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    setIsLoading(false);
                }}
                title={dialogData.title}
                content={dialogData.content}
                onConfirm={dialogData.onConfirm}
            />

            {isLoading && <Loader message={message} />}
            {!isLoading && (
                <>
                    {mode === "add" && (
                        <Typography className='purple' variant='h6'>
                            Add Training
                        </Typography>
                    )}
                    {mode === "view" && (
                        <>
                            <Typography className='purple' variant='h6'>
                                Training Details
                            </Typography>
                            <FormControlLabel
                                value='end'
                                control={<Switch checked={checked} onChange={handleSwitchChange} color='primary' />}
                                label='Edit mode'
                                labelPlacement='end'
                            />
                        </>
                    )}
                    <Box py={1}>
                        {["title", "subTitle", "description", "youtubeLink", "documentLink", "price", "category"].map((entry, key) => {
                            return (
                                <FormControl fullWidth sx={{ mb: 2 }} key={key}>
                                    <OutlinedInput
                                        size='small'
                                        name={entry}
                                        readOnly={mode === "view" && !checked}
                                        value={formData[entry]}
                                        onChange={handleChange}
                                        error={!!errors[entry]}
                                        fullWidth
                                        multiline={entry === "description" ? true : false}
                                        rows={entry === "description" ? 4 : 1}
                                        startAdornment={
                                            <InputAdornment position='start'>
                                                {entry === "youtubeLink" && <YouTubeIcon color='error' />}
                                                {entry === "documentLink" && <ArticleIcon color='primary' />}
                                                {!["youtubeLink", "documentLink"].includes(entry) && (
                                                    <Typography width={90} variant='body1'>
                                                        {entry.charAt(0).toUpperCase() + entry.slice(1)}
                                                    </Typography>
                                                )}
                                                <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            errors[entry] && (
                                                <InputAdornment position='end'>
                                                    <Typography variant='caption' color='error'>
                                                        {errors[entry]}
                                                    </Typography>
                                                </InputAdornment>
                                            )
                                        }
                                    />
                                </FormControl>
                            );
                        })}

                        <Stack mt={isMobile ? 1 : 0} direction={"row"} gap={1}>
                            {mode === "add" && (
                                <Button onClick={handleSubmit} sx={{ backgroundColor: "#30369e" }} variant='contained'>
                                    save training
                                </Button>
                            )}
                            {checked && (
                                <Button onClick={handleSubmit} sx={{ color: "#30369e" }} variant='outlined'>
                                    update training
                                </Button>
                            )}
                            {mode === "view" && (
                                <Button onClick={handleDeleteProject} variant='contained' color='error'>
                                    delete
                                </Button>
                            )}
                        </Stack>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default AddTraining;
