import * as Yup from "yup";

// Define Yup validation schema
const departmentSchema = Yup.object().shape({
    code: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    documentLink: Yup.string().required("Required"),
    categories: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required("Required"),
        })
    ),
});

export default departmentSchema;
