import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getDiscount } from "../../services/discountService";
function DiscountBanner() {
    const [isVisible, setIsVisible] = useState(false);
    const [discount, setDiscount] = useState("");

    // Hide the banner when the close button is clicked
    const handleClose = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        getDiscount()
            .then((res) => {
                if (res.data.data.discountPrice !== "") {
                    setIsVisible(true);
                    setDiscount(res.data.data);
                } else {
                    setIsVisible(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    if (!isVisible) return null;

    return (
        <Box
            sx={{
                width: "100%",
                backgroundColor: "#f0f4ff", // Light yellow background
                borderBottom: "1px solid #cacaca",
                backgroundColor: "#ffecb3", // Light yellow background
                color: "#e41387", // Red text color
                padding: "10px 0",
                textAlign: "center",
                position: "sticky",
                top: 0,
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div class='scrolling-banner'>
                <Typography className="scroll" variant='body1' sx={{ fontWeight: "bold", marginRight: "auto", paddingLeft: "16px" }}>
                    🎉 Exclusive Offer: Get up to {discount.discountType === "amount" ? "₹" : ""} {discount.discountPrice}{" "}
                    {discount.discountType === "percentage" ? "%" : "/-"} off on all projects! Limited Time Only! Hurry Up! 🎉
                </Typography>
            </div>
            <IconButton
                aria-label='close'
                onClick={handleClose}
                sx={{
                    color: "#d32f2f",
                    padding: "0",
                    mr: 2,
                }}
            >
                <CloseIcon />
            </IconButton>
        </Box>
    );
}

export default DiscountBanner;
