import React, { useEffect, useState } from "react";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CachedIcon from "@mui/icons-material/Cached";
import SearchIcon from "@mui/icons-material/Search";
import { getProjects } from "../../../services/projectService";
import { Box, Button, Divider, IconButton, InputAdornment, OutlinedInput, Stack, Tooltip, Typography } from "@mui/material";
import Loader from "../../common/loader";
import { Outlet, useNavigate } from "react-router";
import { isMobile } from "react-device-detect";
import ProjectsTable from "./table";
import { toast } from "react-toastify";
import { isLogin } from "../../../services/authService";
export default function Projects() {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [reload, setReload] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);

    useEffect(() => {
        document.title = "Techlogics | Projects";
        if (!isLogin()) navigate("/admin-login");
        getProjects()
            .then((res) => {
                setProjects(res.data.data);
                setFilteredProjects(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, [reload]);

    const handleSearch = (e) => {
        setSearch(e.target.value);
        const filteredProjects = projects.filter((project) => {
            return project.title.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setFilteredProjects(filteredProjects);
    };

    const handleClearSearch = () => {
        setSearch("");
        setFilteredProjects(projects);
    };

    const handleRefresh = () => {
        setSearch("");
        setIsLoading(true);
        setReload(!reload);
        toast.success("Projects Refreshed");
    };

    return (
        <Box p={2} gap={2} sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
            <Box width={{ xs: "100%", sm: "90%" }}>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <Typography className='purple' variant='h6'>
                        Project List
                    </Typography>
                    <Tooltip title='Refresh' placement='right'>
                        <IconButton size='small' onClick={handleRefresh}>
                            <CachedIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
                <Box py={1} sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                    <OutlinedInput
                        type='text'
                        placeholder='Search Projects'
                        sx={{
                            flex: 1,
                        }}
                        size='small'
                        value={search}
                        onChange={handleSearch}
                        endAdornment={
                            <Tooltip title='Clear'>
                                <IconButton onClick={handleClearSearch}>
                                    <FilterAltOffIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        startAdornment={
                            <InputAdornment position='start'>
                                <SearchIcon color='primary' sx={{ marginRight: 1 }} />
                            </InputAdornment>
                        }
                    />
                    <Button size='medium' sx={{ color: "#30369e" }} variant='contained' color='' onClick={() => navigate("/admin/projects/tab=2/add")}>
                        Add Project
                    </Button>
                </Box>
                {isLoading && <Loader message={"Fetching projects"} />}
                {!isLoading && <ProjectsTable filteredProjects={filteredProjects} />}
            </Box>
            <Divider orientation={isMobile ? "horizontal" : "vertical"} flexItem />
            <Box width={"100%"} sx={{ xs: 0, sm: 1 }}>
                <Outlet />
            </Box>
        </Box>
    );
}
