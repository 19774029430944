import { Avatar, Box, Card, CardContent, CardHeader, Rating, Typography, Button } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";

export default function TestimonialCard({ testimonial }) {
    const [showMore, setShowMore] = React.useState(false);

    const handleToggleShowMore = () => {
        setShowMore((prevShowMore) => !prevShowMore);
    };

    const cardStyles = {
        maxWidth: { xs: "100%", sm: 360 },
        minHeight: 240,
        height: "auto",
        // padding: "10px",
        margin: { xs: "12px 12px", sm: "16px 5px" },
        borderRadius: "8px",
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
            transform: "scale(1.02)",
            boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
        },
    };

    return (
        <Box>
            <Card sx={cardStyles}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
                            {testimonial.name[0].toUpperCase()}
                        </Avatar>
                    }
                    title={testimonial.name}
                    subheader={<Rating name='size-small' defaultValue={testimonial.rating} size='small' readOnly />}
                />
                <CardContent>
                    <Typography
                        variant='body2'
                        sx={{
                            color: "#333",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: showMore ? "unset" : 4, // Limits to 3 lines unless "Read More" is clicked
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {testimonial.message}
                    </Typography>
                    <Button onClick={handleToggleShowMore} size='small' sx={{ mt: 1, color: "primary.main", textTransform: "none" }}>
                        {showMore ? "Show Less" : "Read More"}
                    </Button>
                </CardContent>
            </Card>
        </Box>
    );
}
