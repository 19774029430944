import { Box, Button, Divider, IconButton, InputAdornment, Typography, OutlinedInput, Stack, Switch, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { addDepartment, getDepartment, updateDepartment, deleteDepartment } from "../../../services/departmentService";
import Loader from "../../common/loader";
import departmentSchema from "./schema";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import ActionDialog from "../../common/actionDialog";

export default function AddDepartment({ mode }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [checked, setChecked] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [errors, setErrors] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [department, setDepartment] = React.useState({
        code: "",
        name: "",
        documentLink: "",
        categories: [
            {
                name: "",
            },
        ],
    });

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState({
        title: "",
        content: "",
        onConfirm: null,
        onCancel: null,
        mode: "",
    });

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        setErrors({});
        setChecked(false);
        if (mode === "view") {
            getDepartment(id)
                .then((res) => {
                    setDepartment(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (mode === "add") {
            setDepartment({
                code: "",
                name: "",
                documentLink: "",
                categories: [
                    {
                        name: "",
                    },
                ],
            });
        }
    }, [id, isLoading]);

    // Save Department function with Yup validation
    const saveDepartment = () => {
        setErrors({}); // Clear previous errors
        departmentSchema
            .validate(department, { abortEarly: false })
            .then((validatedData) => {
                if (mode === "add") {
                    setIsLoading(true);
                    setMessage("Saving department");
                    addDepartment(validatedData)
                        .then((res) => {
                            toast.success("Department added");
                            setTimeout(() => {
                                setIsLoading(false);
                                window.location.href = "/admin/departments/tab=1";
                            }, 3000);
                        })
                        .catch((err) => {
                            toast.error(err.response.data.message || "Error adding department");
                            console.log(err);
                            setIsLoading(false);
                        });
                } else if (mode === "view") {
                    setDialogData({
                        mode: "update",
                        title: "Confirm Update",
                        content: `Do you want to update?`,
                        onConfirm: () => {
                            setIsLoading(true);
                            setMessage("Updating department");
                            updateDepartment(department.id, validatedData)
                                .then((res) => {
                                    toast.success("Department updated");
                                    setDialogOpen(false);
                                    setTimeout(() => {
                                        setIsLoading(false);
                                        window.location.href = "/admin/departments/tab=1";
                                    }, 3000);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    toast.error(err.response.data.message || "Error updating department");
                                    setIsLoading(false);
                                });
                        },
                        onClose: () => {
                            setDialogOpen(false);
                            setIsLoading(false);
                        },
                    });
                    setDialogOpen(true);
                }
            })
            .catch((validationErrors) => {
                // Map Yup validation errors to state
                const formattedErrors = {};
                validationErrors.inner.forEach((error) => {
                    formattedErrors[error.path] = error.message;
                });

                setErrors(formattedErrors);
            });
    };

    const handleDeleteDepartment = () => {
        setDialogData({
            mode: "delete",
            title: "Confirm Deletion",
            content: `Are you sure you want to delete? This action cannot be undone`,
            onConfirm: () => {
                setIsLoading(true);
                setMessage("Deleting department");
                deleteDepartment(department.id)
                    .then((res) => {
                        toast.success("Department deleted");
                        setDialogOpen(false);
                        setTimeout(() => {
                            setIsLoading(false);
                            window.location.href = "/admin/departments/tab=1";
                        }, 3000);
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error(err.response.data.message || "Error deleting department");
                        setIsLoading(false);
                    });
            },
            onClose: () => {
                setDialogOpen(false);
                setIsLoading(false);
            },
        });
        setDialogOpen(true);
    };

    return (
        <Box>
            <ActionDialog
                mode={dialogData.mode}
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    setIsLoading(false);
                }}
                title={dialogData.title}
                content={dialogData.content}
                onConfirm={dialogData.onConfirm}
            />
            {isLoading && <Loader message={message} />}
            {!isLoading && (
                <>
                    {mode === "add" && (
                        <Typography className='purple' variant='h6'>
                            Add Department
                        </Typography>
                    )}
                    {mode === "view" && (
                        <>
                            <Typography className='purple' variant='h6'>
                                Department details
                            </Typography>
                            <FormControlLabel
                                value='end'
                                control={<Switch checked={checked} onChange={handleChange} color='primary' />}
                                label='Edit mode'
                                labelPlacement='end'
                            />
                        </>
                    )}
                    <Box py={1}>
                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position='start'>
                                    <Typography width={50} variant='body1'>
                                        Code
                                    </Typography>
                                    <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                </InputAdornment>
                            }
                            sx={{ mb: 2 }}
                            fullWidth
                            readOnly={mode === "view" && !checked}
                            value={department.code}
                            onChange={(e) =>
                                setDepartment({
                                    ...department,
                                    code: e.target.value.toLocaleUpperCase(),
                                })
                            }
                            size='small'
                            variant='outlined'
                            error={!!errors?.code} // Display error if exists
                            endAdornment={
                                errors?.code && (
                                    <InputAdornment position='end'>
                                        <Typography variant='caption' color='error'>
                                            {errors?.code}
                                        </Typography>
                                    </InputAdornment>
                                )
                            }
                        />

                        {/* Name Field */}
                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position='start'>
                                    <Typography width={50} variant='body1'>
                                        Name
                                    </Typography>
                                    <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                </InputAdornment>
                            }
                            fullWidth
                            readOnly={mode === "view" && !checked}
                            sx={{ mb: 2 }}
                            value={department.name}
                            onChange={(e) =>
                                setDepartment({
                                    ...department,
                                    name: e.target.value,
                                })
                            }
                            size='small'
                            variant='outlined'
                            error={!!errors?.name} // Display error if exists
                            endAdornment={
                                errors?.name && (
                                    <InputAdornment position='end'>
                                        <Typography variant='caption' color='error'>
                                            {errors?.name}
                                        </Typography>
                                    </InputAdornment>
                                )
                            }
                        />

                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position='start'>
                                    <Typography width={50} variant='body1'>
                                        Link
                                    </Typography>
                                    <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                </InputAdornment>
                            }
                            fullWidth
                            readOnly={mode === "view" && !checked}
                            sx={{ mb: 2 }}
                            value={department.documentLink}
                            onChange={(e) =>
                                setDepartment({
                                    ...department,
                                    documentLink: e.target.value,
                                })
                            }
                            size='small'
                            variant='outlined'
                            error={!!errors?.documentLink} // Display error if exists
                            endAdornment={
                                errors?.documentLink && (
                                    <InputAdornment position='end'>
                                        <Typography variant='caption' color='error'>
                                            {errors?.documentLink}
                                        </Typography>
                                    </InputAdornment>
                                )
                            }
                        />

                        <Stack direction={"row"} spacing={2} mb={2}>
                            <Typography className='purple' variant='h6'>
                                Categories
                            </Typography>
                            {checked && (
                                <Button
                                    variant='contained'
                                    size='small'
                                    color='black'
                                    sx={{ color: "#30369e" }}
                                    disabled={department.categories[department.categories.length - 1].name === ""}
                                    onClick={() =>
                                        setDepartment({
                                            ...department,
                                            categories: [...department.categories, { name: "" }],
                                        })
                                    }
                                >
                                    Add Category
                                </Button>
                            )}
                        </Stack>

                        {department.categories.map((category, index) => (
                            <Stack direction={"row"} spacing={1} mb={2} key={index}>
                                <OutlinedInput
                                    startAdornment={
                                        <InputAdornment position='start'>
                                            <Typography width={50} variant='body1'>
                                                Name
                                            </Typography>
                                            <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                        </InputAdornment>
                                    }
                                    key={index}
                                    fullWidth
                                    readOnly={mode === "view" && !checked}
                                    sx={{ mb: 2 }}
                                    value={category.name}
                                    onChange={(e) => {
                                        const newCategories = [...department.categories];
                                        newCategories[index].name = e.target.value;
                                        setDepartment({ ...department, categories: newCategories });
                                    }}
                                    size='small'
                                    variant='outlined'
                                    error={!!errors[`categories[${index}].name`]} // Category specific error handling
                                    endAdornment={
                                        <>
                                            {errors[`categories[${index}].name`] && (
                                                <InputAdornment position='end'>
                                                    <Typography variant='caption' color='error'>
                                                        {errors[`categories[${index}].name`]}
                                                    </Typography>
                                                </InputAdornment>
                                            )}
                                            {index !== 0 && checked && (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        color='error'
                                                        variant='outlined'
                                                        onClick={() =>
                                                            setDepartment({
                                                                ...department,
                                                                categories: department.categories.filter((_, i) => i !== index),
                                                            })
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )}
                                        </>
                                    }
                                />
                            </Stack>
                        ))}

                        <Stack direction={"row"} gap={1}>
                            {mode === "add" && (
                                <Button variant='contained' sx={{ backgroundColor: "#30369e" }} onClick={saveDepartment}>
                                    Save Department
                                </Button>
                            )}
                            {checked && (
                                <Button variant='outlined' sx={{ color: "#30369e" }} onClick={saveDepartment}>
                                    update Department
                                </Button>
                            )}
                            {mode === "view" && (
                                <Button variant='contained' color='error' onClick={handleDeleteDepartment}>
                                    delete
                                </Button>
                            )}
                        </Stack>
                    </Box>
                </>
            )}
        </Box>
    );
}
