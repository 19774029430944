// frontend/src/AnimatedCounter.js
import React from "react";
import { useSpring, animated } from "@react-spring/web";

const AnimatedCounter = ({ value }) => {
    const props = useSpring({
        from: { number: 0 },
        to: { number: value },
        reset: true,
        config: { tension: 170, friction: 26 },
    });

    return <animated.div style={{ fontSize: "3rem", fontWeight: "bold", color: "#fff" }}>{props.number.to((n) => n.toFixed(0))}</animated.div>;
};

export default AnimatedCounter;
