import React, { useState } from "react";
import { Box, Typography, TextField, Grid, Button, Container, Card, CardContent, OutlinedInput, InputAdornment, Divider, Stack } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapComponent from "./map";
import Header from "../header/header";
import Footer from "../footer/footer";
import * as yup from "yup";
import { isMobile } from "react-device-detect";
import { addMessage } from "../../services/contactService";
import { toast } from "react-toastify";

const schema = yup.object().shape({
    name: yup.string().required("Required"),
    email: yup.string().email("Invalid email").required("Required"),
    message: yup.string().required("Required"),
    mobile: yup
        .string()
        .matches(/^[0-9]{10}$/, "Invalid mobile number")
        .required("Required"),
});

const ContactUs = () => {
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({
        name: "",
        email: "",
        message: "",
        mobile: "",
    });

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        schema
            .validate(form, { abortEarly: false })
            .then(() => {
                addMessage(form)
                    .then((res) => {
                        setErrors({});
                        setForm({
                            name: "",
                            email: "",
                            message: "",
                            mobile: "",
                        });
                        toast.success("Hurray! We got you. Stay tuned!");
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error("Failed to send. Please Try again!");
                    });
            })
            .catch((err) => {
                const newErrors = {};
                err.inner.forEach((error) => (newErrors[error.path] = error.message));
                setErrors(newErrors);
            });
    };

    return (
        <div>
            <Header />
            <Box className='row' p={2}>
                <Box className='banner-contact'></Box>

                <Box mb={2} width={{ xs: "auto" }}>
                    <Typography variant='h5' align='center' gutterBottom>
                        Get In Touch
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        {["name", "email", "mobile"].map((field, index) => (
                            <OutlinedInput
                                key={index}
                                sx={{ mb: 2 }}
                                size='small'
                                fullWidth
                                name={field}
                                variant='outlined'
                                value={form[field]}
                                onChange={handleChange}
                                startAdornment={
                                    <InputAdornment position='start'>
                                        <Typography width={50} variant='caption'>
                                            {field.charAt(0).toUpperCase() + field.slice(1)}
                                        </Typography>
                                        <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                                    </InputAdornment>
                                }
                                error={!!errors[field]}
                                endAdornment={
                                    errors[field] && (
                                        <InputAdornment position='end'>
                                            <Typography variant='caption' color='error'>
                                                {errors[field]}
                                            </Typography>
                                        </InputAdornment>
                                    )
                                }
                            />
                        ))}
                        <OutlinedInput
                            sx={{ mb: 2 }}
                            size='small'
                            fullWidth
                            name='message'
                            // label='Message'
                            placeholder='Leave your message'
                            variant='outlined'
                            value={form.message}
                            onChange={handleChange}
                            multiline
                            rows={4}
                            error={!!errors.message}
                            endAdornment={
                                errors.message && (
                                    <InputAdornment position='end'>
                                        <Typography variant='caption' color='error'>
                                            {errors.message}
                                        </Typography>
                                    </InputAdornment>
                                )
                            }
                        />
                        <Button fullWidth type='submit' variant='contained' color='primary' size='large'>
                            Send Message
                        </Button>
                    </form>
                    <Stack direction={{ xs: "column", sm: "row" }} gap={2} mt={2}>
                        <Card sx={{ width: { xs: "100%", sm: 500 }, boxShadow: 3, transition: "0.3s", "&:hover": { boxShadow: 6 } }}>
                            <CardContent>
                                <Typography variant='h6' gutterBottom sx={{ color: "#242f62", fontWeight: "bold" }}>
                                    Contact Information
                                </Typography>
                                <Typography variant='body2' sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                                    <EmailIcon sx={{ fontSize: 20, mr: 1 }} color='primary' />
                                    techlogics39@gmail.com
                                </Typography>
                                <Typography variant='body2' sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                                    <PhoneIcon sx={{ fontSize: 20, mr: 1 }} color='primary' />
                                    +91 91331 81444
                                </Typography>
                                <Typography variant='body2' sx={{ display: "flex", alignItems: "center" }}>
                                    <LanguageIcon sx={{ fontSize: 20, mr: 1 }} color='primary' />
                                    www.techlogics.co.in
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ boxShadow: 3, transition: "0.3s", "&:hover": { boxShadow: 6 } }}>
                            <CardContent>
                                <Typography variant='h6' gutterBottom sx={{ color: "#242f62", fontWeight: "bold" }}>
                                    Location
                                </Typography>
                                <Typography variant='body2' sx={{ display: "flex", alignItems: "center" }}>
                                    <LocationOnIcon sx={{ fontSize: 20, mr: 1 }} color='primary' />
                                    39-9-47, SVS Temple Street, MG Rd, Labbipet, Vijayawada, Andhra Pradesh 520010
                                </Typography>
                            </CardContent>
                        </Card>
                    </Stack>
                </Box>
            </Box>
            <MapComponent />
            <Footer />
        </div>
    );
};

export default ContactUs;
