import React from "react";
import { Box, Typography, Container, Grid, Card, CardContent, CardMedia } from "@mui/material";
import Header from "../header/header";
import Footer from "../footer/footer";
function AboutUs() {
    return (
        <>
            <Header />
            <Container className='about-image' sx={{ paddingY: 5, width: "100%" }}>
                <div>
                    <Box p={2} className='text text-about'>
                        <Typography variant='h4' align='center' gutterBottom>
                            About Us
                        </Typography>
                        <Typography variant='body1' align='justify' color='textSecondary'>
                            At Techlogics, we specialize in providing high-quality, affordable academic solutions to help students, researchers, and
                            professionals achieve their goals. Whether you need assistance with academic projects, dissertations, publications, assignments, or
                            are looking for internships and training opportunities, we have you covered.
                        </Typography>

                        <Box sx={{ marginY: 4 }}>
                            <Typography variant='h5' gutterBottom>
                                Our Services
                            </Typography>
                            <Typography variant='body1' align='justify' color='textSecondary'>
                                We are committed to providing top-tier academic and research support designed to help students, researchers, and professionals
                                achieve excellence:
                            </Typography>
                            <ol>
                                <li>
                                    <Typography>Final Year Projects for B.E, M.E, and M.S Students</Typography>
                                    <Typography variant='body1' color='textSecondary'>
                                        We specialize in guiding students through their final-year projects, offering practical, hands-on experience in a range
                                        of disciplines. Our projects are aligned with the latest industry and academic standards, ensuring a strong foundation
                                        in real-world applications.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>R&D for Latest Project Ideas and IEEE Papers</Typography>
                                    <Typography variant='body1' color='textSecondary'>
                                        We help you stay on the cutting edge of technology with innovative project ideas and research guidance. Our focus on
                                        IEEE standards ensures that your projects are technically sound and up to date with current industry trends.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>Paper Writing & Publication Support</Typography>
                                    <Typography variant='body1' color='textSecondary'>
                                        We offer comprehensive support for writing and publishing research papers in prestigious international journals,
                                        including:
                                        <ul>
                                            <li>SCI (Science Citation Index)</li>
                                            <li>Scopus</li>
                                            <li>Springer</li>
                                            <li>UGC Care</li>
                                        </ul>
                                        Our team ensures that your research meets the highest standards, offering guidance from manuscript preparation to
                                        successful publication.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>Technical Workshops</Typography>
                                    <Typography variant='body1' color='textSecondary'>
                                        Master the latest technologies through our expert-led workshops, which cover:
                                        <ul>
                                            <li>DevOps</li>
                                            <li> Artificial Intelligence (AI)</li>
                                            <li>Machine Learning (ML)</li>
                                            <li>Data Science</li>
                                            <li>Internet of Things (IoT)</li>
                                            <li>UI/UX Design</li>
                                        </ul>
                                        Our workshops are designed to provide practical insights and hands-on experience, catering to both beginners and
                                        advanced learners.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>Plagiarism-Free Reports and Advanced Formatting</Typography>
                                    <Typography variant='body1' color='textSecondary'>
                                        We guarantee plagiarism-free reports, using advanced tools like LaTeX and other formatting software to ensure your
                                        documents meet the highest standards of academic integrity and presentation.
                                    </Typography>
                                </li>
                            </ol>
                        </Box>

                        <Box sx={{ marginY: 4 }}>
                            <Typography variant='h5' gutterBottom>
                                Our platform offers:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant='body1' color='textSecondary'>
                                        Customized Academic Projects: Get access to a wide range of projects in various fields tailored to your specific needs.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='body1' color='textSecondary'>
                                        Dissertation & Publication Assistance: Expert guidance to help you through every step of your research journey.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='body1' color='textSecondary'>
                                        Training & Internships: Enhance your skills and gain real-world experience with our internship programs and training
                                        sessions.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='body1' color='textSecondary'>
                                        Affordable Prices: We are committed to offering top-notch services without breaking the bank.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='body1' color='textSecondary'>
                                        Easy-to-Use Platform: Browse, apply, and manage your academic and professional needs effortlessly.
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    </Box>
                </div>
            </Container>
            <Footer />
        </>
    );
}

export default AboutUs;
